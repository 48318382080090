footer {
  &.footer-pages {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size: 1.2rem;

    .footer-text {
      &:last-child {
        font-size: 1.3rem;
        font-weight: 600;
        line-height: 1.8;

        .link-tyc {
          color: $color-yellow;
        }
      }
    }

  }

  &.sepro {
    background-color: $color-green;
    text-align: center;
    padding: 4.5rem 0;

    .footer-main-info {
      .footer-info {
        text-align: left;
        padding: 0 0 2rem;

        &-contact {
          text-align: left;
        }

        @media (max-width:$desktop) {
          text-align: center;
          padding: 0 1.2rem 2rem;
        }
      }

      @media (max-width:$desktop) {
        display: none;
      }
    }

    .footer-main-logo {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      @media (max-width:$desktop) {
        align-items: center;
        justify-content: center;
      }

      .footer-logo {
        width: 360px;
        height: 75px;

        @media (max-width:$desktop) {
          width: 255px;
          height: 65px;
        }
      }
    }

    @media (max-width: $desktop) {
      padding: 2rem 0;
    }

  }
}