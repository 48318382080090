.section-two {
  width: 100%;

  @media (max-width:$desktop) {
    overflow: hidden;
  }

  .pages-internal {
    width: 100%;
    height: 100%;
    // position: sticky;
    top: 0;
    display: inline-block;

    // background-image: url('./assets/images/sepro-bg.png');
    // background-repeat: no-repeat;
    // background-size: cover;
    // overflow: hidden;
    .container {
      @media (max-width:$desktop) {
        max-width: 100%;
      }
    }
  }

  padding: 0;

  .content-info {
    padding-top: 0.1vh;
    position: relative;
    // min-height: 100vh;
  }
}

.pages-internal {
  &-header {
    text-align: center;
    height: 30vh;

    img {
      height: 100%;
      width: auto;
    }
  }


  &-body {
    // height: 100vh;
    margin-top: 0vh;

    // @include media-query(desktop) {
    //   height: 50vh;
    //   margin-top: 12vh;
    // }

    .information {
      height: 100%;
      // padding: 2.5rem 0 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      // @include media-query(tablet) {
      //   padding: 2.5rem 0;
      // }

      padding: 6rem 0 0;
      background-color: $color-green;

      @media (max-width:$desktop) {
        background-color: transparent;
        padding: 0;
      }

      &-content {
        max-width: 80%;
        padding-right: 4rem;


        @include media-query(desktopXL) {
          max-width: 70%;
        }

        &.full {
          max-width: 100%;
          padding-top: 0;
          // background: linear-gradient(90deg, $color-blue, $color-dark-green);
          background-color: $color-blue-v3;
          padding-right: 0rem;
          padding: 2% 0;

          @media(max-width:$desktop) {
            background: linear-gradient(90deg, $color-dark-blue, $color-blue );
          }
        }

        &-highlight {
          margin-bottom: 2rem;

          .description {
            font-size: 30px;
          }

          &__one {
            @media (max-width:$desktop) {
              display: none;
            }

            span,
            h1 {
              font-size: 18px;
              font-weight: 400;
              margin: 0;
              display: contents;
            }
          }

          &__two {
            // margin-left: -5rem;
            padding: 2.5rem 8rem 2.5rem 29rem;
            position: relative;

            @media (max-width:$desktopXL) {
              padding: 2.5rem 8.5rem 2.5rem 15rem;
              margin-left: 0rem;
            }

            @media (max-width:$desktopL) {
              padding: 2.5rem 8.5rem 2.5rem 10rem;
              margin-left: 0rem;
            }

            @media (max-width:$desktop) {
              text-align: center;
              padding: 2.5rem 8.5rem 2.5rem 3.2rem;
              margin-left: 0rem;
            }

            @media (max-width:$tablet) {
              padding: 2.5rem 6.5rem 2.5rem 3rem;
            }

            .description {
              color: $color-white;
              font-size: 26px;

              b {}
            }

            &::after {
              content: "";
              position: absolute;
              background-image: url("./assets/images/arrow-sepro.svg");
              background-repeat: no-repeat;
              background-size: 100% 100%;
              z-index: 1;
              right: 2rem;
              bottom: 0;
              transform: translateY(-50%);
              width: 71px;
              height: 24px;
              transition: all .5s linear;

              @media (max-width:$desktop) {
                transform: rotate(90deg);
                transition: all .5s linear;
                top: 84%;
                background-size: cover;
                width: 31px;
                height: 32px;
                right: 4rem;
              }
            }
          }

        }
      }

      &-header {
        display: flex;
        justify-content: space-between;
        font-weight: $bold;

        .information-number {
          font-size: 5rem;

          @include media-query(tablet) {
            font-size: 6rem;
          }

          @include media-query(desktop) {
            font-size: 7rem;
          }
        }

        .information-title {
          font-size: 2.5rem;
          text-align: right;

          @include media-query(tablet) {
            font-size: 3.5rem;
          }

          @include media-query(desktop) {
            font-size: 4.5rem;
          }
        }
      }

      &-body {
        width: 55%;
        padding-left: 0;

        @include media-query(mobilex) {
          width: 60%;
          height: 60%;
        }

        @include media-query(tablet) {
          padding-left: 5rem;
          height: auto;
        }

        @include media-query(desktop) {
          width: 50%;
        }
      }

      &-footer {
        position: relative;

        .box-yellow {
          height: 4.5rem;
          border-radius: 1rem;
          display: flex;
          align-items: center;
          padding-left: 2rem;
          background: $color-yellow;
          background: linear-gradient(80deg, rgba($color: $color-yellow, $alpha: 1.0) 0%, rgba($color: $color-yellow, $alpha: 1.0) 39%, rgba($color: $color-yellow, $alpha: 0%) 100%);

          @include media-query(desktop) {
            height: 5.5rem;
          }

          .btn {
            font-size: 2rem;
            height: 4rem;

            @include media-query(desktop) {
              font-size: 2.2rem;
              height: 4.5rem;
            }

            span {
              color: inherit;
              font-size: 2.5rem;
              font-weight: $semibold;
              margin-left: 1rem;

              @include media-query(desktop) {
                font-size: 3rem;
              }

              &::before {
                color: inherit;
              }
            }
          }

        }

        .image-master {
          position: absolute;
          right: -6rem;
          bottom: 0;
          width: auto;
          height: 40vh;
          z-index: -1;

          @include media-query(desktop) {
            left: 60%;
            right: initial;
            bottom: -3rem;
            transform: translateX(-50%);
          }
        }
      }

      .footer-specializations {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        .box-buttons {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          width: 100%;

          @include media-query(mobile) {
            flex-direction: row;
            gap: 3rem;
          }

          @include media-query(desktop) {
            width: 65%;
          }

          .btn {
            width: 100%;
            font-size: 1.6rem;
            padding: 0.5rem 2rem;

            @include media-query(mobile) {
              width: 50%;
            }

            @include media-query(tablet) {
              font-size: 2rem;
              padding: 0.5rem 3rem;
            }

            @include media-query(desktop) {
              min-width: 50%;
            }

            &::after {
              @include media-query(maxtablet) {
                right: 1rem;
              }
            }
          }
        }

        .icon-slogan {
          display: none;
          width: fit-content;

          @include media-query(mobile) {
            margin-right: -3rem;
          }

          @include media-query(desktop) {
            display: block;
          }

          @include media-query(desktopLS) {
            margin-right: -8rem;
          }

          img {
            width: 20rem;
          }
        }

        .image-specializations {
          position: absolute;
          right: -4rem;
          bottom: 8rem;
          height: 40vh;
          width: auto;
          z-index: -1;

          @include media-query(mobile) {
            right: -8rem;
            bottom: 3rem;
            height: 48vh;
          }

          @include media-query(desktop) {
            left: 65%;
            right: initial;
            bottom: -1rem;
            transform: translateX(-70%);
          }
        }
      }

      .footer-conferences {
        .box-yellow {
          width: 75%;

          @include media-query(desktop) {
            position: relative;
          }

          @include media-query(desktopLS) {
            width: 80%;
          }
        }

        .image-conference {
          position: absolute;
          right: -2rem;
          bottom: 2rem;
          width: auto;
          height: 40vh;
          z-index: -1;

          @include media-query(mobile) {
            right: -4rem;
          }

          @include media-query(desktop) {
            bottom: -1rem;
            right: -6%;
            transform: none;
          }

          @include media-query(desktopLS) {
            left: 78%;
            height: 44vh;
          }
        }
      }

    }

    .register-steps {
      overflow: hidden;
      padding: 6rem 4rem 8rem;
      background-color: #E0E0E0;

      .main-steps {
        position: relative;
        width: 600px;
        padding: 2rem 0 4rem;

        @media (max-width:$desktopXL) {
          width: 100%;
        }

        .step {
          margin: 2rem 0 0;
        }

        .owl-stage-outer {
          overflow: hidden;
        }

        .owl-dots {
          position: absolute;
          width: 100%;
          height: auto;
          left: 0;
          bottom: -10%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;

          @media (max-width:$mobilexl) {
            bottom: -10%;
          }

          .owl-dot {
            background-color: #659AC2;
            opacity: 0.5;
            width: 85px;
            height: 11px;
            margin-right: 1rem;
            border-radius: 0.2rem;
            transition: all .5s linear;
            border: 0;
            cursor: pointer;

            &.active {
              transition: all .5s linear;
              background-color: $color-blue-v2;
              opacity: 1;
            }
          }
        }



      }

      .owl-indicators {
        @media (max-width:$desktop) {
          text-align: center;
        }

        .custom-dot {
          width: 83px;
          height: 83px;
          background: #909090;
          padding: 10px;
          transition: all .5s linear;
          margin-right: 25px;
          border: none;
          border-radius: 10px;
          color: $color-white;
          cursor: pointer;

          &.active {
            // background: linear-gradient(90deg, $color-dark-green, $color-blue);
            transition: all .5s linear;
            background: rgb(0, 133, 80);
            background: -moz-linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
            background: -webkit-linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
            background: linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008550", endColorstr="#39a900", GradientType=1);


            @media(max-width:$tablet) {
              background: $color-green;
            }

          }

          span {
            font-size: 50px;
            -webkit-text-stroke-width: 4px;
          }
        }
      }
    }
  }

  .register-form {
    @media (max-width:$desktop) {
      padding: 0 2.5rem 2.5rem;
    }

    @media (max-width:$tablet) {
      padding: 0 1.5rem 1.5rem;
    }

  }



}