.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  opacity: 0;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &.show {
    opacity: 1;
    pointer-events: auto;
    overflow: auto;
  }

  &-info {
    max-width: 50rem;
    width: 100%;
    margin: 0% auto;
    padding: 0 1.5rem;

  }

  &-modalbox {
    position: relative;
    // padding: 10rem 2rem 5rem;
    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease-in;
    transition: all 500ms ease-in;
  }

  .close {
    width: 2.8rem;
    height: 2.8rem;
    background-color: transparent;
    background-image: url(./assets/images/close-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $color-white;
    line-height: 2.5rem;
    position: absolute;
    right: 0;
    top: -6.5rem;
    font-size: 3rem;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;


    transition: all 0.2s ease-in-out;

    &:hover {
      color: $color-yellow;
    }
  }

}

.modal-alert {
  &-register {
    background: linear-gradient(90deg, rgba($color: $color-dark-blue, $alpha: 85%), rgba($color: $color-blue, $alpha: 85%));
  }

  &-modalbox {
    background-color: #238C1F;
    padding: 4rem 6rem 4rem 4rem;
    border-radius: 2.2rem;
    max-height: 85vh;

    .modalbox__body {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      h4 {
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 3rem;
        color: $color-white;
      }

      a {
        margin: 0 auto;
        justify-content: center;
        font-size: 16px;
        background-color: $color-yellow;
        color: $color-blue;

        &:hover {
          background-color: #39A900;
          color: $color-blue;
        }
      }
    }
  }
}

.modal-register {
  background: linear-gradient(90deg, rgba($color: $color-dark-blue, $alpha: 85%), rgba($color: $color-blue, $alpha: 85%));


  &-modalbox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5rem;

    // background: rgba($color: $color-bg-modal, $alpha: 85%);
    &.modal-login {
      border-radius: 2.2rem;
    }
  }

  .content-register,
  .content-login {

    .title,
    .subtitle {
      font-weight: $extrabold;
      text-transform: uppercase;
      text-align: center;
    }

    .title {
      color: $color-blue-v2;
      font-size: 2.2rem;
      line-height: 0.5;

      @include media-query(mobilex) {
        font-size: 2.5rem;
      }

      @include media-query(mobile) {
        font-size: 3.2rem;
      }
    }

    .subtitle {
      color: $color-blue-medium-v2;
      font-size: 3.5rem;
      margin-bottom: 1.5rem;

      @include media-query(mobilex) {
        font-size: 4.5rem;
      }

      @include media-query(mobile) {
        font-size: 6rem;
      }
    }

    .btn {
      margin: 0 auto;
      font-size: 1.7rem;
      display: flex;
      justify-content: center;
      padding: 0 1.5rem;

      @include media-query(mobilex) {
        font-size: 2rem;
        padding: 0 3rem;
      }
    }
  }
}

.modal-programans {
  background: rgba(0, 0, 0, 0.7);

  .close {
    top: -1rem;
    right: -1.2rem;
  }

  &-info {
    max-width: 80rem;
  }

  &-modalbox {
    padding: 2.5rem 6rem 4rem 4rem;
    background-color: $color-white;
    border-radius: 3rem;
    max-height: 85vh;

    .modalbox {
      &__header {
        position: relative;
        margin-bottom: 1.5rem;

        .modalbox__title {
          color: $color-blue-medium;
          font-size: 3rem;

          @include media-query(mobile) {
            font-size: 3.5rem;
          }

          @include media-query(desktop) {
            font-size: 4rem;
          }

        }
      }

      &__body {
        position: relative;
        padding-left: 1rem;
        max-height: 63vh;
        height: 100%;

        .list__programs {
          list-style: none;
          overflow: hidden;
          overflow-y: auto;
          max-height: inherit;
          height: 100%;
          padding-right: 1rem;

          &__item {
            margin-bottom: 1.3rem;

            .link {
              color: $color-blue-medium;
              font-size: 2rem;
              font-weight: 700;
              text-decoration: none;
              line-height: 1.4;
              cursor: pointer;
            }
          }

          &.style-scrooll::-webkit-scrollbar-thumb {
            border: 5px solid $color-yellow;
          }

          &.style-scrooll::-webkit-scrollbar-track {
            background-color: $color-blue-medium;
          }
        }
      }

    }


  }
}

.modal-postgraduate {
  background: rgba(0, 0, 0, 0.6);

  .close {
    top: -1rem;
    right: -4rem;
    background: $color-yellow;
    color: $color-blue-medium;

    &:hover {
      background: $color-blue-medium;
      color: $color-yellow;
    }
  }

  &-info {
    max-width: 80rem;
  }

  &-modalbox {
    background-color: $color-bg-modal-v1;
    padding: 2.5rem 6rem 4rem 4rem;
    max-height: 85vh;
    overflow: auto;

    .modalbox {
      &__header {
        position: relative;

        .modalbox__title {
          color: $color-yellow;
          font-size: 3rem;

          @include media-query(mobile) {
            font-size: 3.5rem;
          }

          @include media-query(desktop) {
            font-size: 4rem;
          }

        }
      }

      &__body {
        p {
          font-size: 1.6rem;
        }

        h4,
        h5,
        h6 {
          text-align: center;
          font-weight: $bold;
          font-size: 1.6rem;

          &:first-of-type {
            margin-top: 2rem;
          }
        }

        .modalbox {
          &__subtitle {
            color: $color-yellow;
            font-size: 2rem;
            margin-bottom: 1rem;
            width: 50%;
          }

          &__description {
            margin-bottom: 1rem;
          }

          &__button {
            margin: 4rem 0 2rem;
            display: flex;
            justify-content: center;

            .btn {
              position: relative;

              &::after {
                content: "";
                display: block;
                background-image: url('./assets/images/border-btn-subscribe.svg');
                background-repeat: no-repeat;
                background-position: center;
                position: absolute;
                top: 1.7rem;
                left: -1.5rem;
                width: 110%;
                height: 100%;
              }
            }
          }
        }
      }
    }
  }
}

.modal-sendemail {
  background: rgba(0, 0, 0, 0.7);

  .close {
    top: -1rem;
    right: -4.2rem;

    @include media-query(mobilexl) {
      right: -1.2rem;
    }
  }

  &-info {
    max-width: 60rem;
    margin: 10% auto;
  }

  &-modalbox {
    padding: 2.5rem 6rem 4rem 4rem;
    background: rgba(205, 209, 216, 0.85);
    border-radius: 2rem;

    .modalbox {
      &__header {
        position: relative;
        margin-bottom: 1.5rem;

        .modalbox__title {
          color: $color-blue-medium;
          font-size: 3rem;
          text-align: center;

          @include media-query(mobile) {
            font-size: 3.5rem;
          }

          @include media-query(desktop) {
            font-size: 4rem;
          }

        }
      }

      &__body {
        position: relative;
        padding-left: 1rem;
        max-height: 63vh;
        height: 100%;

        .text-paragraph {
          color: $color-blue-dark;
        }

        .btn__container {
          display: flex;
          justify-content: center;
          margin-top: 4rem;
        }
      }

    }


  }
}