// Bootstrap variables

// override default bootstrap colors for accessibility
$color-white: #ffffff;
$color-yellow: #FFD300;
$color-blue: #004A93;
$color-blue-v2: #035de5;
$color-dark-blue: #212844;
$color-blue-medium: #0043A8;
$color-blue-medium-v2: #0047a8;
$color-blue-dark: #011135;
$color-gray-light: #EBF1F7;
$color-placeholder: #A8A5A5;
$color-text-input: #5a5a5b;
$color-bg-modal: #cdd1d8;
$color-bg-pages: #001B54;
$color-bg-modal-v1: #031c54;
$color-blue:#004a90;
$color-blue-v2:#09427D;
$color-blue-v3:#0061A9;
$color-orange: #ED6D16;

$color-green: #39A800;
$color-dark-green: #006E3C;

$color-soft-black: #575756;
/////////////////////////////
$color-turquoise-referred-pre: #337786;
$color-yellow-referred-pre: #FFE533;
$color-orange-bg-referred-pre: #EC806F;
$color-gray-text-referred-pre: #8C8C8C;
$color-yellow-btn-referred-pre: #FFDF00;
$color-blue-text-referred-pre: #045668;
$color-darkgray-text-referred-pre: #5C5C5C;
$color-softgray-bg-referred-pre: #C3C3C3;
$color-yellow-owl-referred-pre: #FFD12F;
$color-blue-text-referred-pos: #203F9E;
$color-skyblue-text-referred-pos: #55C3EE;
$color-darkblue-bg-referred-pos: #003268;
$color-skyblue-owl-referred-pos: #14A6DF;


// ==================== BREAKPOINTS ====================
$mobilexs: 320px !default;
$mobilex: 375px !default;
$mobilexl: 480px !default;
$mobile: 576px !default;
$tablet: 768px !default;
$desktop: 992px !default;
$desktopL: 1200px !default;
$desktopLS: 1440px !default;
$desktopXL: 1600px !default;


// ==================== FONT WEIGHTS ====================
$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;
$extrabold: 800;

// ==================== PROJECT ESPECIFICS ====================
$header-height: 9rem;

.swal2-container.swal2-backdrop-show {

    background: linear-gradient(90deg, rgba(33, 40, 68, 0.85), rgba(0, 74, 147, 0.85)) !important;
}

.swal2-title {
    font-size: 31px !important;
    color: $color-blue !important;
    font-weight: 700 !important;
}

.swal2-actions {
    .swal2-confirm {
        font-size: 20px !important;
        padding: 11px 20px !important;
        font-weight: 700 !important;
        background-color: $color-green !important;

        &:hover{
            background-color: $color-green !important;
            color: $color-white !important;
            font-weight: 700 !important;
        }
    }
}


.swal2-icon.swal2-success {
    .swal2-success-line-long {
        background-color: $color-green !important;

    }

    .swal2-success-ring {
        border: 1px solid $color-green !important;
    }

    .swal2-success-line-tip {
        background-color: $color-green !important;
    }
}