main.no-found {
  .container {
    position: relative;
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-query(desktop) {
      padding-top: 14.5vh;
    }
    
  }

  .section {
    padding-top: 0;
    margin-top: -5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    margin-top: 10vh;

    @include media-query(tablet) {
      width: 80%;
    }
    @include media-query(desktop) {
      width: 70%;
    }
    @include media-query(desktopL) {
      width: 60%;
    }

    .title {
      font-size: 40vw;
      margin: 0 0;
      
      @include media-query(desktop) {
        font-size: 20vw;
      }
    }

    .text-paragraph {
      font-size: 1.5rem;
    }

    .btn__container {
      margin-top: 2rem;
    }
    
    .box-form {
      @include media-query(desktop) {
        margin-top: 4rem;
      }
    }


  }
}