.services-section{
  padding: 7rem 0;
  @media (max-width:$desktop) {
    overflow: hidden;
  }   
  .container{
    @media (min-width:$desktopL) {
      max-width: 1060px;
    }
  }
  h2{
    font-size: 3.6rem;
    position: relative;
    padding-left: 2.5rem;
    margin-bottom:5rem;
    span{
      display: block;
      font-weight: 500;
    }
    &::after{
      content: "";
      position: absolute;
      background-color: $color-yellow;
      height: 100%;
      width: 1.2rem;
      left: 0;
      top:0;
      z-index: 10;
    }
  }
  .services{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    position: relative;
    @media (max-width:$desktop) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }    
    &-card{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      max-width: 500px;
      min-height: 250px;
      gap: 15px;
      background-color: rgba($color: $color-dark-blue, $alpha: 0.7);
      border-radius: 10px;
      padding: 3rem 3.7rem;
      @media (max-width:$desktop) {
        flex-direction: column;
        min-height: 350px;
      }  
      @media (max-width:$tablet) {
        max-width: 100%;
      }
      
    }

    &-icon{
      width: 20%;      
    }

    &-info{
      width: 75%;
      @media (max-width:$desktop) {
        width: 100%;
      }   
    }
    &-title{
      margin-bottom: 3rem;
      span{
        display: block;
      }
    }
  
    &-title,
    p{
      font-size: 2rem;
    }

    .owl-dots {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      bottom: -10%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    
      @media (max-width:$mobilexl) {
        bottom: -10%;
      }
    
      .owl-dot {
        background-color: #6692BE;
        width: 85px;
        height: 11px;
        margin-right: 1rem;
        border-radius: 1.2rem;
        transition: all .5s linear;
        border: 0;
        cursor: pointer;
    
        &.active {
          transition: all .5s linear;
          background-color: $color-blue;
        }
      }
    }
  }
}