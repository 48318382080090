.container {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  margin-right: auto;
  margin-left: auto;
  
  @media (min-width: 576px) {
    max-width: 540px;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
  }

  @media (min-width: 768px) {
    max-width: 720px;
  }

  @media (min-width: 992px) {
    max-width: 960px;
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }

  @media (min-width: 1440px) {
    max-width: 1400px;
  }
  &-fluid{
    max-width: 100%;
    width: 100%;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    margin-right: auto;
    margin-left: auto;
    @media (max-width:$desktop) {
      
    }
  }
}



// Definimos las variables para el sistema de grillas
$grid-columns: 12;
$grid-gutter-width: 30px;

// Definimos las variables para los puntos de quiebre
$breakpoints: (
  sm: 320px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

// Definimos la clase para las filas
.row {
  margin-left: -($grid-gutter-width / 2);
  margin-right: -($grid-gutter-width / 2);
  display: flex;
  flex-wrap: wrap;
  div[class*="col"]{
    position: relative;
  }
}

// Definimos las clases para las columnas
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
  position: relative;
  width: 100%;
  padding-left: ($grid-gutter-width / 2);
  padding-right: ($grid-gutter-width / 2);
}

// Definimos los anchos de las columnas
.col-1 {
  width: calc((100% / #{$grid-columns}) * 1);
}

.col-2 {
  width: calc((100% / #{$grid-columns}) * 2);
}

.col-3 {
  width: calc((100% / #{$grid-columns}) * 3);
}

.col-4 {
  width: calc((100% / #{$grid-columns}) * 4);
}

.col-5 {
  width: calc((100% / #{$grid-columns}) * 5);
}

.col-6 {
  width: calc((100% / #{$grid-columns}) * 6);
}

.col-7 {
  width: calc((100% / #{$grid-columns}) * 7);
}

.col-8 {
  width: calc((100% / #{$grid-columns}) * 8);
}

.col-9 {
  width: calc((100% / #{$grid-columns}) * 9);
}

.col-10 {
  width: calc((100% / #{$grid-columns}) * 10);
}

.col-11 {
  width: calc((100% / #{$grid-columns}) * 11);
}

.col-12 {
  width: calc((100% / #{$grid-columns}) * 12);
}


// Definimos los anchos de las columnas para pantallas pequeñas
@media (min-width: map-get($breakpoints, sm)) {
  .col-sm-1 {
    width: calc((100% / #{$grid-columns}) * 1);
  }

  .col-sm-2 {
    width: calc((100% / #{$grid-columns}) * 2);
  }
  .col-sm-3 {
    width: calc((100% / #{$grid-columns}) * 3);
  }
  
  .col-sm-4 {
   width: calc((100% / #{$grid-columns}) * 4);
  }
  
  .col-sm-5 {
    width: calc((100% / #{$grid-columns}) * 5);
  }
  
  .col-sm-6 {
   width: calc((100% / #{$grid-columns}) * 6);
  }
  
  .col-sm-7 {
    width: calc((100% / #{$grid-columns}) * 7);
  }
  
  .col-sm-8 {
    width: calc((100% / #{$grid-columns}) * 8);
  }
  
  .col-sm-9 {
    width: calc((100% / #{$grid-columns}) * 9);
  }
  
  .col-sm-10 {
    width: calc((100% / #{$grid-columns}) * 10);
  }
  
  .col-sm-11 {
    width: calc((100% / #{$grid-columns}) * 11);
  }
  
  .col-sm-12 {
  width: calc((100% / #{$grid-columns}) * 12);
  }
    
}


// Definimos los anchos de las columnas para pantallas medianas
@media (min-width:  map-get($breakpoints, md)) {
  .col-md-1 {
    width: calc((100% / #{$grid-columns}) * 1);
  }
  
  .col-md-2 {
    width: calc((100% / #{$grid-columns}) * 2);
  }
  
  .col-md-3 {
    width: calc((100% / #{$grid-columns}) * 3);
  }
  
  .col-md-4 {
    width: calc((100% / #{$grid-columns}) * 4);
  }
  
  .col-md-5 {
    width: calc((100% / #{$grid-columns}) * 5);
  }
  
  .col-md-6 {
    width: calc((100% / #{$grid-columns}) * 6);
  }
  
  .col-md-7 {
    width: calc((100% / #{$grid-columns}) * 7);
  }
  
  .col-md-8 {
    width: calc((100% / #{$grid-columns}) * 8);
  }
  
  .col-md-9 {
    width: calc((100% / #{$grid-columns}) * 9);
  }
  
  .col-md-10 {
    width: calc((100% / #{$grid-columns}) * 10);
  }
  
  .col-md-11 {
    width: calc((100% / #{$grid-columns}) * 11);
  }
  
  .col-md-12 {
   width: calc((100% / #{$grid-columns}) * 12);
  }
}


// Definimos los anchos de las columnas para pantallas grandes
@media (min-width:  map-get($breakpoints, lg)) {
  .col-lg-1 {
    width: calc((100% / #{$grid-columns}) * 1);
  }
  
  .col-lg-2 {
    width: calc((100% / #{$grid-columns}) * 2);
  }
  
  .col-lg-3 {
    width: calc((100% / #{$grid-columns}) * 3);
  }
  
  .col-lg-4 {
    width: calc((100% / #{$grid-columns}) * 4);
  }
  
  .col-lg-5 {
    width: calc((100% / #{$grid-columns}) * 5);
  }

  .col-lg-6 {
    width: calc((100% / #{$grid-columns}) * 6);
  }
  
  .col-lg-7 {
   width: calc((100% / #{$grid-columns}) * 7);
  }
  
  .col-lg-8 {
    width: calc((100% / #{$grid-columns}) * 8);
  }
  
  .col-lg-9 {
    width: calc((100% / #{$grid-columns}) * 9);
  }
  
  .col-lg-10 {
    width: calc((100% / #{$grid-columns}) * 10);
  }
  
  .col-lg-11 {
    width: calc((100% / #{$grid-columns}) * 11);
  }
  
  .col-lg-12 {
    width: calc((100% / #{$grid-columns}) * 12);
  }

}