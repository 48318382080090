.slider {

  .owl-nav {
    .owl-prev,
    .owl-next {
      width: 2.5em;
      height: 2.5em;
      background-size: cover;
      top: 40%;

      &:hover {}
    }

    .owl-prev {
      background-image: url('./assets/images/arrow-left-active-slider.svg');
      left: -4.5rem;
    }
  
    .owl-next {
      background-image: url('./assets/images/arrow-rigth-active-slider.svg');
      right: -4.5rem;
    }
  }

}