.find-program {
  background-color: $color-white;
  padding: 3rem 0 0;

  @media (max-width:$desktop) {
    padding: 3rem 1.5rem 0;

  }

  h1 {
    font-size: 28px;
    font-weight: 300;

    span {
      font-weight: 700;
    }
  }


  .program-mode {
    margin: 3rem 0;

    &-actions {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 1.5rem;

      @media (max-width:$desktop) {
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }
    }

    .btn-tag {
      color: $color-white;
      background-color: $color-soft-black;

      &:hover,
      &:active,
      &:focus,
      &:visited,
      &.active {
        background-color: $color-green;
      }

      @media(max-width:$desktop) {
        color: $color-white;
        background-color: $color-soft-black;

        &:hover,
        &:active,
        &:focus,
        &:visited,
        &.active {
          background-color: $color-blue;
        }
      }
    }
  }

  .row {
    .find-program-form {

      // position: sticky;
      // top: 5%;
      &.bg-gradient-green {
        background: rgb(0, 133, 80);
        background: -moz-linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
        background: -webkit-linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
        background: linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008550", endColorstr="#39a900", GradientType=1);
      }

      input.btn-blue {
        font-size: 16px;
        padding: 11px 73px;
        background-color: $color-blue;
        height: 39px;

        &:hover {
          background-color: $color-green;
        }
      }

      input[type=radio] {
        accent-color: $color-orange;
      }

      #id_type {
        label {
          display: flex;
          align-items: center;
          padding-top: 0.3em;
          input[type=radio] {
            accent-color: $color-orange;
            appearance: none;
            border-radius: 50%;
            border: 1px solid white;
            height: 1em;
            width: 1em;
            margin-right: 0.5em;
            &:checked {
              background-color: $color-orange;
              outline: 1px solid $color-blue;
              outline-offset: -2px;
            }
          }
        }
      }

    }
  }

  &-form {
    border-radius: 1.2rem;
    padding: 3rem 2rem;
    max-height: 450px;

    .input-text {
      font-size: 2rem;
      font-weight: 700;
      color: $color-white;
      padding-bottom: 1rem;
    }

    .input-group {
      padding-bottom: 2rem;
      margin-bottom: 1rem;
      position: relative;

      label.error {
        color: $color-white;
        font-weight: 700;
        position: absolute;
        width: 100%;
        height: auto;
        z-index: 1;
        bottom: 0px;
        left: 0;
      }
    }

    select {
      width: 100%;
      height: 26px;
      border: 0;
      border-radius: .8rem;
      color: $color-soft-black;
      margin-top: 1rem;
      padding: .5rem 1rem;
    }

    input[type="radio"] {
      width: 18px;
      height: 18px;
      accent-color: $color-yellow;
      border: 0;
    }

    .btn-blue {
      margin: 0 auto;
    }
  }

  .program-card {
    background-color: #F9F9F9;
    box-shadow: -3px 4px 6px 1px #bfbebe9c;
    padding: 5rem 6rem;
    margin: 0rem 3rem 3rem;
    border-radius: 2.2rem;

    @media (max-width:$desktop) {
      padding: 3rem;
      margin: 1.8rem 0;
      overflow: hidden;
    }

    &-title {
      font-size: 36px;
      font-weight: 700;
      margin-bottom: 4rem;
    }

    &-info {
      font-size: 18px;
      margin-bottom: 4rem;

      p {
        font-weight: 600;

        span {
          font-weight: 400;
        }
      }
    }

    &-video {
      margin-bottom: 4rem;

      iframe {
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }
    }

    &-description {
      margin-bottom: 4rem;

      p {
        font-size: 18px;
        color: black;
      }
    }

    &-actions {
      .btn-yellow {
        margin: 0 auto;
        color: $color-blue;
        font-size: 2rem;
        justify-content: center;
        width: 320px;

        @media (max-width:$tablet) {
          width: 100%;
        }

        &:hover {
          color: $color-white;
          background: $color-blue;
        }
      }
    }
  }


  .additional-program-info {
    padding: 0rem 3rem;

    @media (max-width:$desktop) {
      padding: 3rem 0;
      overflow: hidden;

    }

    h2 {
      font-size: 3.6rem;
      margin-bottom: 1.2rem;

      span {
        display: block;
      }
    }

    .steps-list {
      display: flex;
      flex-wrap: wrap;
      position: relative;

      @media (max-width:$desktop) {
        padding-bottom: 5rem;

        .owl-item {
          padding: 0 1rem;
        }

        .owl-dots {
          bottom: 0;

          .owl-dot {
            background-color: #bfbebe9c;

            &.active {
              background-color: $color-green;
            }
          }
        }
      }

      .step-card {
        background-color: #F9F9F9;
        box-shadow: -3px 4px 6px 1px #bfbebe9c;
        width: 45%;
        padding: 1.8rem;
        margin: 1.2rem;
        border-radius: 1.2rem;

        @media (max-width:$desktop) {
          width: 100%;
          margin: 0;
          min-height: 100%;
        }

        &:first-child {
          h3 {
            &::after {
              content: "";
              position: absolute;
              background-image: url(./assets/images/arrows-green-our-programs.svg);
              background-repeat: no-repeat;
              background-size: 100% 100%;
              z-index: 1;
              right: 2rem;
              bottom: 0;
              transform: translateY(-50%);
              width: 71px;
              height: 24px;
              transition: all 0.5s linear;


            }
          }
        }

        &-header {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          padding-bottom: 2rem;
          border-bottom: 1px solid $color-soft-black;
          position: relative;



          &-indicator {
            width: 20%;

            img {
              width: 100%;
              height: auto;
            }
          }

          h3 {
            padding: 0 1rem 20px;
            width: 80%;
            font-size: 1.8rem;
            font-weight: 700;
            color: $color-soft-black;


          }
        }

        &-body {
          p {
            padding: 1rem;
            text-align: right;
            padding-left: 3rem;

          }
        }
      }
    }

    .text-keep-in-mind {
      margin: 31px 0px;

      @media (min-width:$desktop) {
        display: none;
      }

      h2 {
        font-size: 36px;
        margin-bottom: 2rem;
        text-transform: uppercase;
        text-align: center;

        span {
          color: $color-white;
          background: linear-gradient(90deg, #212844, #004A93);
        }
      }
    }
  }

  .additional-steps {

    margin: 3rem;

    @media (max-width:$desktop) {
      background-color: #F9F9F9;
      box-shadow: -3px 4px 6px 1px #bfbebe9c;
      border-radius: 2.2rem;
      margin: 3rem 1rem;
      padding: 1.6rem;
    }

    .main-info {
      justify-content: flex-start;
      align-items: flex-start;
      display: flex;

      @media (max-width:$desktop) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 15%;
        // box-shadow: -7px 8px 15px 0px #00000040;

        @media(min-width:$desktop) {
          border-radius: 20px;
        }
      }
    }

    .additional-info {
      padding-left: 2rem;

      h2 {
        margin-bottom: 2rem;
        font-weight: 400;

        @media (max-width:$desktop) {
          font-size: 2.5rem;
          margin: 2rem 0;
        }
      }

      p {
        margin-bottom: 2rem;
        font-weight: 400;
      }
    }
  }




}

.questions {
  padding: 3rem 6rem;

  @media (max-width:$desktop) {
    padding: 3rem 2rem;
  }

  h2 {
    font-size: 3.6rem;
    margin-bottom: 4rem;
  }

  &-block {
    display: flex;
    border-bottom: 1px dashed $color-white;
    padding: 3rem 0;

    @media (max-width:$desktop) {
      flex-direction: column;
      padding: 3rem;
      border-bottom: none;
      border-radius: 2rem;
      background-color: $color-dark-blue;
      min-height: 100%;
    }

    h3 {
      font-size: 1.6rem;
      font-weight: 700;
      width: 50%;

      @media (max-width:$desktop) {
        width: 100%;
        margin: 2rem 0;
        position: relative;
        border-bottom: 1px dashed $color-white;
        padding: 2rem 0;
      }
    }

    p {
      font-size: 1.4rem;
      font-weight: 300;
      width: 50%;
      padding-left: 1.2rem;

      @media (max-width:$desktop) {
        width: 100%;
      }
    }

    &-list {
      @media (max-width:$desktop) {

        overflow: hidden;
        position: relative;
        padding: 3rem 0;
      }
    }

  }

  .owl-dots {
    bottom: 0;

    .owl-dot {
      &.active {
        background-color: $color-blue;
      }
    }
  }
}