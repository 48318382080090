.events {
  background-color: $color-white;
  padding: 1.4rem 0;

  &-title {
    position: relative;
    padding-left: 8.5rem;
    margin-bottom: 4rem;
    display: flex;

    &::after {
      content: "";
      position: absolute;
      background: url("./assets/images/calendar-icon.svg") no-repeat center;
      background-size: 100% auto;
      width: 8.5rem;
      height: 8.5rem;
      z-index: 9;
      top: 0;
      left: 8px;
    }

    h1,
    h2 {
      font-weight: 700;
      font-size: 3.6rem;
      color: $color-white;
      width: fit-content;
      border-radius: 1rem;
      margin-top: 1rem;
      padding: 1rem 3rem;

      @media(max-width:$mobile) {
        font-size: 3rem;
      }

      @media(max-width:$mobilexl) {
        font-size: 1.9rem;
      }
    }
  }

  .events-row {
    display: flex;
    flex-flow: row wrap;
    column-gap: 3rem;
    padding: 3.5rem 0;

    @media (min-width:$desktop) {
      padding: 7rem 0;
    }
  //   display: grid;
  //   grid-template-columns: repeat(2, minmax(0, 1fr));

  //   @media (max-width:$desktop) {
  //     grid-template-columns: repeat(1, minmax(0, 1fr));
  //   }
  }

  // &-section {
  //   // margin: 7rem 0;
  // }

  $list-column-gap: 2rem;
  &-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // overflow: hidden;
    padding: 5rem 1rem;
    position: relative;
    flex-flow: row wrap;
    column-gap: $list-column-gap;
    row-gap: 4rem;
    justify-content: space-between;

    @media (min-width:$desktop) {
      column-gap: 4rem;
    }

    @media (max-width:$desktop) {
      .owl-stage {
        padding-left: 0 !important;

        .owl-item {
          width: calc(50% - 20px);

        }
      }
    }

    .owl-dots {
      bottom: 3%;

      .owl-dot {
        width: 74px;
        height: 11px;
        background-color: #909090;

        &.active {
          background-color: $color-green;
        }
      }
    }
  }

  &-card {
    background-color: #F9F9F9;
    box-shadow: -3px 4px 6px 1px #bfbebe9c;
    border-radius: 10px;
    min-height: 435px;

    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    max-width: 26.5rem;

    // @media (min-width:$tablet) {
    //   width: calc(50% - (#{$list-column-gap} / 2));
    // }

    // @media (min-width:$desktop) {
    //   width: calc(33.33% - ( (#{$list-column-gap} * 2) / 3 ));
    // }

    &-header {
      position: relative;

      &-hour {
        color: $color-white;
        background-color: $color-orange;
        padding: .5rem 1.5rem;
        font-size: 1.8rem;
        border-radius: 4px;
        position: absolute;
        top: 0;
        left: 50%;
        width: 55%;
        transform: translate(-50%, -50%);
        z-index: 1;
        text-align: center;
        font-size: 20px;
        font-weight: 400;
        font-weight: 500;

        // @media(max-width: $desktop) {
        //   color: $color-blue;
        // }
      }

      .events-card-img {
        max-height: 170px;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    &-body {
      padding: 2rem;

      p {
        font-size: 1.8rem;
        color: $color-blue;

        span {
          font-weight: 600;
        }

        .event-card-description__small{
          color: $color-blue;
          font-size: 1.4rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
        }

        &.event-card-speaker {
          color: $color-soft-black;
        }
      }

    }


    &-footer {
      text-align: center;
      padding: 1rem 0 2rem;
      margin-top: auto;

      .btn-event {
        background-color: #909090;
        color: white;
        padding: 1rem 2rem;
        border-radius: 10px;
        font-size: 2rem;
        border: none;
        min-width: 200px;
        text-decoration: none;

        @media (max-width:$desktop) {
          min-width: 85%;
        }

        &.live {
          background-color: #F03838;
        }

        &.soon {
          background-color: $color-blue;
        }
      }
    }
  }
}