.box-form {
  border-radius: 2.2rem;

  // max-width: 63rem;
  // margin-top: 4rem;
  width: 100%;
  // @media (max-width:$desktopXL) {
  //   padding: 4rem 5rem;
  // }
  // @media (max-width:$desktop) {
  //   border-radius: 4rem;
  //   padding: 4rem 2rem;
  // }
  // position: absolute;
  // top: 0;
  // z-index: 9999;

  @include media-query(desktopL) {
    // position: absolute;
    // z-index: 1;
    // top: -8rem;
  }

  &.bg-gradient-green {
    background: rgb(0, 133, 80);
    background: -moz-linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
    background: -webkit-linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
    background: linear-gradient(90deg, rgba(0, 133, 80, 1) 0%, rgba(57, 169, 0, 1) 65%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008550", endColorstr="#39a900", GradientType=1);
  }


  .title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
    text-align: center;

    @media (max-width:$desktopXL) {
      font-size: 3rem;

    }

    @media (max-width:$mobilexl) {
      font-size: 2.5rem;
      margin-bottom: 1.6rem;
    }

    span {
      display: block;
    }
  }

  .row {
    justify-content: space-around;
    padding: 0 15px;

    @include media-query(desktop) {
      padding: 0;
    }
  }

  .input-field {
    margin-bottom: 1rem;
    padding: 0 5px;

    label.error {
      color: $color-white;
      font-size: 12px;
      margin-top: 10px;

      &::before {
        content: "";
        display: inline-block;
        width: 1.5rem;
        height: 1.3rem;
        background-image: url("./assets/images/icon-error.svg");
        background-size: contain;
        margin-right: 0.7rem;
      }
    }

    label {
      margin-bottom: 1rem;
    }

    input,
    select {
      &.error {
        border: 1px solid red;
      }
    }

    &.errors {
      .errors {
        display: none;
        color: $color-white;
        font-size: 1.4rem;
        margin-top: 2rem;

        &::before {
          content: "";
          display: inline-block;
          width: 1.5rem;
          height: 1.3rem;
          background-image: url("./assets/images/icon-error.svg");
          background-size: contain;
          margin-right: 0.7rem;
        }

        &.block {
          display: block;
        }
      }

      .errorlist {
        list-style: none;
        font-size: 1.4rem;

        ul.errorlist {
          padding-left: 2rem;
          list-style: disc;
        }
      }
    }

    &.radio-button {
      text-align: center;

      .text-form {
        font-weight: $semibold;
        margin-bottom: 0.5rem;
      }

      .content__radio {
        display: flex;
        justify-content: center;
        gap: 2rem;
      }
    }

    &.tyc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;

      #tyc-error {
        width: 100%;
        order: 3;
      }

      label {
        font-size: 1.1rem;
        order: 1;
        width: 94%;

        a {
          color: $color-white;
          text-decoration-color: $color-gray-light;
        }
      }
    }

    &.button {
      padding-top: 2rem;
      display: flex;
      justify-content: center;

      @media (max-width:$desktopXL) {
        padding-top: 0rem;

      }

      .btn-blue {
        width: 100%;
        justify-content: center;
      }
    }



  }

  #recoveryPassword {
    label {
      font-size: 1.5rem;
    }
  }

  .option-user {
    display: flex;
    justify-content: center;
    row-gap: 1.5rem;
    margin-top: 2rem;

    &-link {
      font-size: 1.4rem;
      color: $color-white;
      text-decoration: underline;

      &:hover {
        color: $color-yellow;
      }
    }

    @media (max-width:$tablet) {
      flex-direction: column;
      align-items: center;
      row-gap: 0.5rem;
    }
  }

  &-login {
    padding: 5rem;

    input {
      height: 44px;
    }

    .title {
      margin-bottom: 3rem;
    }

    .btn-yellow {
      margin: 2rem 0;
      color: $color-blue;
      background-color: $color-yellow;
      font-size: 2rem;
      width: 100%;
      justify-content: center;

      &:hover {
        background: inherit;
        background-color: $color-blue;
        color: $color-white;

      }
    }

    p {
      margin-right: 1rem;
    }

    .option-user-link {
      color: $color-yellow;
      transition: all .5s ease;

      &:hover {
        transition: all .5s ease;
        color: $color-blue;
      }
    }
  }

  &-register {
    @media (max-width:$desktopXL) {
      height: 70vh;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
      }

      &::-webkit-scrollbar-track {
        background-color: #6692BE;

      }

      &::-webkit-scrollbar-thumb {
        background-color: $color-blue;
        border-radius: 6px;
        // border: 1px solid black;
        border: 1px solid $color-blue;

      }
    }

    h2 {
      color: $color-blue;
      font-size: 2.4rem;
    }

    padding:5rem;

    .input-field {

      label,
      label.error,
      .errors {
        color: $color-blue;
        font-size: 13px;
      }

      input[type="checkbox"] {
        border: 1px solid $color-soft-black;
      }

      &.tyc {
        label {
          a {
            color: $color-blue;
            text-decoration-color: $color-gray-light;
            font-weight: 700;
          }
        }
      }
    }

    input:not([type="checkbox"]),
    select {
      transition: all .5s linear;
      height: 2.5rem;
      padding: 0 1rem;
      font-size: 16px;

      &:focus {
        outline: 2px solid $color-blue;
      }
    }
  }
}