.tooltip {
  background-color: transparent;
  position: absolute;
  width: 3.5%;
  height: 2.5%;
  cursor: pointer;

  &::before,
  &::after {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    background-color: $color-white;
    z-index: 9999;
  }

  &::before {
    content: ' ';
    width: 15px;
    height: 15px;
    transform: translate(-50%, calc(-100% - 5px)) rotate(45deg);
  }

  &::after {
    content: attr(data-departament);
    border-radius: 10px;
    color: $color-blue-medium;
    font-weight: $bold;
    padding: .6rem 1rem;
    text-align: center;
    transform: translate(-50%, calc(-100% - 10px));
    min-width: 10rem;
    width: max-content;
  }

  &:hover {

    &::before,
    &::after {
      display: block;
    }
  }

  &.pt1 {
    top: 33.8%;
    left: 31%;
  }
  &.pt2 {
    top: 9.5%;
    left: 34.3%;
  }
  &.pt3 {
    top: 48.3%;
    left: 47.3%;
  }
  &.pt4 {
    top: 12.3%;
    left: 31.3%;
  }
  &.pt5 {
    top: 43.3%;
    left: 50.7%;
  }
  &.pt6 {
    top: 45.7%;
    left: 34.3%;
  }
  &.pt7 {
    top: 64.9%;
    left: 44.1%;
  }
  &.pt8 {
    top: 43.3%;
    left: 63.7%;
  }
  &.pt9 {
    top: 62.5%;
    left: 18.1%;
  }
  &.pt10 {
    top: 19.3%;
    left: 41%;
  }
  &.pt11 {
    top: 40.9%;
    left: 18.1%;
  }
  &.pt12 {
    top: 21.7%;
    left: 24.6%;
  }
  &.pt13 {
    top: 45.7%;
    left: 44.1%;
  }
  &.pt14 {
    top: 57.8%;
    left: 83.5%;
  }
  &.pt15 {
    top: 57.8%;
    left: 34.3%;
  }
  &.pt16 {
    top: 4.4%;
    left: 53.3%;
  }
  &.pt17 {
    top: 14.5%;
    left: 37.7%;
  }
  &.pt18 {
    top: 55.5%;
    left: 54%;
  }
  &.pt19 {
    top: 66.2%;
    left: 8.4%;
  }
  &.pt20 {
    top: 26.5%;
    left: 47.5%;
  }
  &.pt21 {
    top: 72.1%;
    left: 31.3%;
  }
  &.pt22 {
    top: 45.8%;
    left: 24.7%;
  }
  &.pt23 {
    top: 16.8%;
    left: 28.1%;
  }
  &.pt24 {
    top: 36.2%;
    left: 50.7%;
  }
  &.pt25 {
    top: 50.6%;
    left: 27.9%;
  }
  &.pt26 {
    top: 55.4%;
    left: 24.7%;
  }
  &.pt27 {
    top: 67.4%;
    left: 60.6%;
  }
  &.pt28 {
    top: 45.8%;
    left: 76.9%;
  }

}