@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot?56ojej');
  src:  url('./assets/fonts/icomoon.eot?56ojej#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf?56ojej') format('truetype'),
    url('./assets/fonts/icomoon.woff?56ojej') format('woff'),
    url('./assets/fonts/icomoon.svg?56ojej#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-right-round:before {
  content: "\e903";
  color: #023e62;
}
.icon-arrow:before {
  content: "\e900";
}
.icon-arrow-lefth:before {
  content: "\e901";
}
.icon-arrow-right:before {
  content: "\e902";
}
.icon-menu-hamburger:before {
  content: "\e904";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-whatsapp:before {
  content: "\ea93";
}
