* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	font-size: 62.5%;
	scroll-behavior: smooth;
	height: 100%;
}

body {
	font-size: 1.6rem;
	font-family: 'Work Sans', sans-serif;
	font-weight: $regular;
	color: $color-white;
	// height: 100vh;
	// background-image: url('./assets/images/sepro_bg_home.png');
	background-image: url('./assets/images/content/13-03-25/sepro-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 0;
	background-attachment: fixed;
	overflow-x: hidden;
	position: relative;

	@media (max-width:$tablet) {
		// background-image: url('./assets/images/sepro-bg-mobile.png');
		background-image: url('./assets/images/content/junio-24/sepro-bg-mobile.png');
		background-size: cover;
	}
}



.p-line-height {
	line-height: 1.5;
}

img {
	max-width: 100%;
	height: auto;
}

section {
	// height: 100vh;


	&.section--with-header {
		padding-top: 0rem;
	}

}

b {
	font-weight: bold;
}

.height-100-view {
	height: 100vh;
}

.height-100-porcentaje {
	height: 100%;
}

.d-none {
	display: none !important;
}

.overhidden {
	overflow: hidden;
}

.link {
	text-decoration: none;
	color: $color-white;
}

.box-shadow-primary {
	box-shadow: 0 0 1.5rem 0.5rem rgba(0, 0, 0, 0.1);
}

.text-center {
	text-align: center;
}

.border-2 {
	border-top: 2px solid $color-white;
	border-bottom: 2px solid $color-white;
}

.mb-1 {
	margin-bottom: 1rem;
}

.mb-15 {
	margin-bottom: 1.5rem;
}

.mb-2 {
	margin-bottom: 2rem;
}


.pt {
	&-1 {
		padding-top: 1rem;
	}

	&-2 {
		padding-top: 2rem;
	}
}




// INPUTS
.input {
	height: 3.3rem;
	width: 100%;
	background-color: $color-gray-light;
	border: 0;
	border-radius: 0.6rem;
	padding: 0.5rem 1rem;

	&-text {
		color: $color-blue-dark;
		font-weight: $medium;
		font-size: 1.5rem;
		margin-top: 5px;
	}

	&::placeholder {
		color: $color-text-input;
	}

	&:focus {
		outline: 2px solid $color-yellow;
	}
}

.radioinput {
	width: 18px;
	height: 18px;
	background-color: $color-white;
	border-radius: 50%;
	border: 1px solid $color-placeholder;
	margin-right: 0.688rem;
	position: relative;
	appearance: none;

	&:checked {
		border: 1px solid $color-yellow;

		&::before {
			position: absolute;
			content: "";
			display: block;
			width: 8px;
			height: 8px;
			background-color: $color-yellow;
			border-radius: 50%;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

.checkboxinput {
	width: 18px;
	height: 18px;
	background-color: $color-white;
	border-radius: 5px;
	border: 1px solid $color-gray-light;
	position: relative;
	appearance: none;

	&:checked {
		background-color: $color-yellow;
		border: 1px solid $color-yellow;

		&::before {
			position: absolute;
			font-family: "icomoon";
			content: "\ea10";
			display: block;
			font-size: 1.1rem;
			color: $color-white;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}

// Style for scroll
.style-scrooll {
	&::-webkit-scrollbar {
		width: 3px;
		height: 3px;
		transition: all 300ms;
	}

	&::-webkit-scrollbar-track {
		background-color: $color-gray-light;
		border-radius: 10px;

	}

	&::-webkit-scrollbar-thumb {
		border-radius: 30px;
		border: 5px solid $color-yellow;

		&:hover,
		&:active {
			cursor: pointer;
		}
	}

	&:hover {
		&::-webkit-scrollbar {
			width: 9px;
			height: 9px;
		}
	}
}


// NEW PARAMS
.bg-green-text {
	background-color: $color-green;
	color: white;
	border-radius: 10px;
	padding: 5px 12px;
}

.bg-gradient-blue {
	// background: linear-gradient(90deg, $color-dark-blue, $color-blue );
	background-color: $color-blue;
}

.bg-menu {
	background: linear-gradient(90deg, #212844e6, #004a90e6);
}

.gradient-blue {
	background: linear-gradient(90deg, $color-dark-blue, $color-blue );

}

.bg-gradient-green {
	background-color: $color-green;
}

.bg-white {
	background-color: white;
	border-radius: 2.2rem;
}

.uniminuto-brand {
	position: fixed;
	top: 0;
	right: 0;
	width: 400px;
	height: 100px;
	z-index: 10;

	img {
		width: 100%;
		height: auto;
	}

	@media (max-width:$mobilexl) {
		width: 70%;
	}
}

.owl-nav.disabled {
	display: none;
}

.owl-dots {
	position: absolute;
	width: 100%;
	height: auto;
	left: 0;
	bottom: -10%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	@media (max-width:$mobilexl) {
		bottom: -10%;
	}

	.owl-dot {
		background-color: #6692BE;
		width: 85px;
		height: 11px;
		margin-right: 1rem;
		border-radius: 1.2rem;
		transition: all .5s linear;
		border: 0;
		cursor: pointer;

		&.active {
			transition: all .5s linear;
			background-color: $color-dark-blue;
		}
	}
}