.header {
  position: fixed;
  width: 100%;
  height: 90px;
  top: 0;
  left: 0;
  z-index: 100;
  // background: rgb(0, 133, 80);
  // background: -moz-linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
  // background: -webkit-linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
  // background: linear-gradient(90deg, rgba(0, 133, 80, 1) 5%, rgba(57, 169, 0, 1) 82%);
  // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#008550", endColorstr="#39a900", GradientType=1);
  background-color: $color-blue-v2;

  .container {
    position: relative;
    height: 100%;
  }

  .header__info {
    position: relative;
    // padding-top: 3rem;
    width: 100%;
    height: 100%;
    // @include media-query(desktop) {
    //   position: absolute;
    // }
  }

  .row {
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    // &.fixed {
    //   display: grid;
    //   grid-template-columns: repeat(3, 1fr);
    //   gap: 2rem;

    //   .column-left {
    //     img {
    //       width: 80%;

    //       @include media-query(desktop) {
    //         max-width: 19rem;
    //         width: 100%;
    //       }
    //     }
    //   }

    //   .column-center {
    //     img {
    //       width: 100%;

    //       @include media-query(desktop) {
    //         max-width: 38rem;
    //       }
    //     }
    //   }

    //   .colum-right {
    //     .bth__header {
    //       // z-index: 999;

    //       // @include media-query(desktop) {
    //       //   position: fixed;
    //       //   right: 10vw;
    //       //   // right: 7vw;
    //       // }
    //       // @include media-query(desktopXL) {
    //       //   right: 13vw;
    //       // }
    //     }
    //   }
    // }

    .navbar {

      &-brand {
        display: none;

        @include media-query(desktop) {
          display: block;
        }

        &.logged {
          display: block;
        }



        img {
          width: 100%;
          height: auto;
          position: absolute;
          width: 100%;
          left: 0;
          z-index: 1;
          top: -25px;
        }

        &.logged {


          @media (max-width:$desktop) {
            padding: 0;

            img {
              width: 100%;
              height: auto;
              position: relative;
              top: 0px;
            }
          }

        }
      }

      &-menu {
        display: flex;
        justify-content: center;
        align-items: center;

        #sepro-menu {

          &.logged {
            .navbar-nav {
              list-style: none;
              display: flex;
              justify-content: center;
              align-items: center;



              .nav-item {
                .nav-link {
                  font-size: 18px;
                  color: $color-white;
                  text-decoration: none;
                  margin-left: 2rem;
                  transition: all .4s ease;

                  &:hover {
                    color: $color-yellow;
                    transition: all .2s ease;
                  }

                  &.active {
                    color: $color-yellow;
                    transition: all .2s ease;
                  }
                }
              }
            }
          }

          .navbar-nav {
            list-style: none;
            display: flex;
            justify-content: center;
            align-items: center;



            .nav-item {
              .nav-link {
                font-size: 18px;
                color: $color-white;
                text-decoration: none;
                margin-left: 2rem;
                transition: all .4s ease;

                &:hover {
                  color: $color-yellow;
                  transition: all .2s ease;
                }

                &.active {
                  color: $color-yellow;
                  transition: all .2s ease;
                }
              }
            }
          }

        }

        @media (max-width:$desktop) {
          display: none;
        }
      }

      &-actions {
        @media (max-width:$desktop) {
          display: flex;
          justify-content: flex-start;
          align-items: center;
        }

        &.logged {
          .btn-green-outline {
            border: 2px solid $color-yellow;

            &:hover {
              background-color: $color-yellow;
            }
          }
        }

        .bth__header {
          display: flex;
          justify-content: flex-end;
          gap: 1rem;
          align-items: center;

          @media(max-width: $desktop) {
            // gap: 3.5rem;
            justify-content: space-between;
            width: 100%;
          }



          .user-name {
            color: $color-yellow;
            display: flex;
            height: 4.5rem;
            padding: 0 3rem;
            display: flex;
            align-items: center;
            text-decoration: none;
            transition: 0.3s color, 0.3s background-color, 0.3s border;


          }

          .btn__login {
            font-size: 1.4rem;
            padding: 0.2rem 0.3rem;

            &#btnRegister {
              border: 3px solid $color-green;
              border-radius: 10px;

              &:hover {
                background-color: $color-orange;
                border: 3px solid $color-orange;

              }

              @media(max-width: $desktop) {
                width: 100%;
                justify-content: center;
                padding: 11px 33px;
                // border: 3px solid $color-blue;
                border-radius: 10px;

                &:hover {
                  background-color: $color-green;
                  border: 3px solid $color-green;
                }

              }

            }

            &#btnLogin {
              background-color: $color-green;
              border-radius: 10px;
              color: $color-white;
              border: 2px solid $color-green;

              @media(max-width: $desktop) {
                width: 100%;
                justify-content: center;
                padding: 11px 33px;
                // background-color: $color-blue;
                // border: 2px solid $color-blue;

                &:hover {
                  background-color: $color-green;
                  border: 3px solid $color-green;
                }

              }
            }

            @include media-query(maxdesktop) {
              border-radius: 0.5rem;
              height: auto;
            }

            @include media-query(mobile) {
              padding: 0.2rem 1rem;
              font-size: 1.6rem;
            }

            @include media-query(tablet) {
              padding: 0.2rem 2rem;
              font-size: 1.8rem;
              border-radius: 0.8rem;
            }

          }

          .btn-menutoggle {
            padding: 0.3rem 0;
            width: 45%;

            @include media-query(maxdesktop) {
              height: auto;
            }

            @include media-query(tablet) {
              width: 40%;
            }

            @include media-query(desktop) {
              width: auto;
            }

            img {
              height: 100%;
            }
          }


          &.fixed {
            position: fixed;
            right: 10vw;

            .btn-menutoggle {

              @include media-query(maxtablet) {
                width: 40%;
              }

              @include media-query(mobilexl) {
                width: 35%;
              }

              @include media-query(maxmobile) {
                width: 30%;
              }

              &.userlogin {
                width: 60%;

                @include media-query(desktop) {
                  width: auto;
                }
              }
            }
          }
        }

        &.logged {

          @media (max-width:$desktop) {
            display: none;
          }
        }

        &-mobile {
          display: none;

          .user-name {
            color: $color-yellow;
          }

          .btn-menutoggle {
            padding: 0;
          }

          @media (max-width:$desktop) {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;
          }
        }



      }


    }
  }

  .colum-right {}

  &--onlymenu {
    .row {
      justify-content: flex-end;
    }

    .bth__header {
      position: fixed;
      z-index: 999;

      .btn-menutoggle {
        padding: 0.3rem 0;
        width: 45%;

        @include media-query(maxdesktop) {
          height: auto;
        }

        @include media-query(mobile) {
          width: 65%;
        }

        @include media-query(tablet) {
          width: 70%;
        }

        @include media-query(desktop) {
          width: auto;
        }

        &.userlogin {
          width: 60%;

          @include media-query(desktop) {
            width: auto;
          }
        }

        img {
          height: 100%;
        }
      }
    }
  }

}