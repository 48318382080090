body {
  &.about {
    background-color: $color-bg-pages;
    background-image: url('./assets/images/background-pages-internal.png');
    background-attachment: initial;

    .section-header {
      text-align: center;
      height: 30vh;

      img {
        height: 100%;
        width: auto;
      }
    }

    .section-body {
      padding-bottom: 7rem;

      .margin {
        margin: 5rem 0;
      }

      .mb-2 {
        margin-bottom: 2rem;
      }
    }

    .recognitions {
      .title {
        margin-bottom: 5rem;
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-bottom: 7rem;

        @include media-query(mobile) {
          grid-template-columns: 1fr 1fr;
        }
        @include media-query(tablet) {
          grid-template-columns: repeat(4, 1fr);
        }
      }

      &__card {
        border: 2px solid $color-white;
        border-radius: 1.5rem;
        line-height: 1.5;
        padding: 1.5rem 1rem;

        .recognitions__title {
          margin-bottom: 0;
        }
      }

    }

    .certification {
      &__info {
        padding-left: 1.5rem;
        line-height: 1.5;

        ul {
          padding-left: 2rem;
          display: flex;
          flex-wrap: wrap;
          gap: 0.5rem 4rem;
        }
      }
    }

    .coverage {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      row-gap: 4rem;

      @include media-query(mobile) {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &__data {
      }

      &__map {
        position: relative;
        width: 80%;

        @include media-query(mobile) {
          width: auto;
        }
        img {
          max-width: 47rem;
          width: 100%;
          display: block;
        }
      }
    }

    .world {
      margin-bottom: 8rem;

      &__data {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 2rem;
        
        @include media-query(tablet) {
          flex-direction: row;
          align-items: center;
        }

        &__img {
          img {
            max-width: 24.5rem;
            width: 100%;
          }
        }
      }
    }

    .patents {
      margin-bottom: 6rem;

      .title {
        margin-bottom: 5rem;
      }

      &__list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 2rem;
        margin-bottom: 5rem;

        @include media-query(tablet) {
          grid-template-columns: repeat(3, 1fr);
        }
      }

      &__card {
        border: 2px solid $color-white;
        border-radius: 1.5rem;
        line-height: 1.5;
        padding: 1.5rem 1rem;

        .patents__code {
          margin-bottom: 0;
        }
      }
    }

    .pcis,
    .center_progresses {
      .title {
        margin-bottom: 2rem;
      }

      &__data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        gap: 3rem;
        
        @include media-query(tablet) {
          flex-direction: row;
          align-items: center;
          gap: 2rem;
        }

        &__info {
          @include media-query(tablet) {
            width: 75%;
          }
        }

        &__img {
          width: fit-content;
        }
      }
    }

    .pcis {
      margin-bottom: 7rem;

      .title {
        @include media-query(tablet) {
          padding-left: 1.5rem;
        }
      }
    }

    .center_progresses {
      &__data {
        @include media-query(maxtablet) {
          flex-direction: column-reverse;
        }

        .title {
          @include media-query(maxtablet) {
            span {
              display: initial;
            }
          }
        }
      }
    }

    .experience {
      margin: 10rem 0 5rem;

      .title {
        margin-bottom: 2rem;
        
        @include media-query(tablet) {
          margin-bottom: 4rem;
        }
      }

      &__video {
        width: 100%;
        margin: auto;

        @include media-query(tablet) {
          width: 80%;
        }
        @include media-query(desktop) {
          width: 70%;
        }
        @include media-query(desktopL) {
          width: 50%;
        }

        &__responsive {
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
          max-width: 100%;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .title {
      @include media-query(maxtablet) {
        span {
          display: block;
        }
      }
    }

  }
}