body {
  &.postgraduate {
    .header {
      .row.fixed {
        .colum-right {
          .bth__header {
            right: 5vw;
          }
        }
      }
    }
  }
}

.mastery,
.specialization {
  margin-bottom: 8rem;

  .section-body {
    position: relative;
    height: 100%;
    padding-top: 3.5vh;
    
    @include media-query(desktop) {
      padding-top: 20.5vh;
    }
  }

  .postgraduate {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    @include media-query(desktop) {
      display: grid;
      grid-template-columns: 60% 40%;
      align-items: center;
    }

    &__img {
      justify-self: flex-end;

      img {
        width: 100%;
      }
    }

    &__data {
      width: 100%;
      
      @include media-query(desktop) {
        margin-top: 12rem;
        margin-left: -6rem;

      }

      &__header {
        color: $color-yellow;

        .postgraduate__type {
          margin: 0;
          font-size: 5rem;

          @include media-query(desktopLS) {
            font-size: 6rem;
          }
        }

        .postgraduate__title {
          font-size: 2.2rem;
          
          @include media-query(desktopLS) {
            font-size: 2.5rem;
          }
        }
      }

      &__body {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        @include media-query(desktop) {
          align-items: flex-end;
        }

        & > div {
          width: 80%;
          display: flex;
          flex-direction: column;
          align-items: center;
          row-gap: 3rem;

          @include media-query(desktop) {
            align-items: flex-end;
          }

          @include media-query(desktopLS) {
            width: 65%;
          }
        }

        .btn {
          height: 4rem;
          font-size: 1.8rem;
          
          @include media-query(mobile) {
            font-size: 2rem;
          }
        }
      }
    }
  }
}