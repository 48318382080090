.btn {
  display: inline-block;
  border: none;
  border-radius: 1.1rem;
  color: inherit;
  background-color: transparent;
  font-size: 2rem;
  font-weight: $bold;
  height: 4.5rem;
  padding: 0 3rem;
  transition: 0.3s color, 0.3s background-color, 0.3s border;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;

  &-primary {
    color: $color-blue;
    background-color: $color-white;
    border: 2px solid $color-white;

    &:hover {
      color: $color-white;
      background-color: transparent;
    }
  }

  &-second {
    color: $color-white;
    background-color: $color-blue;
    border: 2px solid $color-blue;

    &:hover {
      color: $color-white;
      background-color: transparent;
    }
  }

  &-third {
    color: $color-blue;
    background-color: $color-yellow;
    border: 2px solid $color-yellow;

    &--blue-medium {
      color: $color-blue-medium;
      background-color: $color-yellow;
      border: 2px solid $color-yellow;
    }

    &:hover {
      color: $color-yellow;
      background-color: transparent;
    }

  }

  &-fourth {
    color: $color-white;
    background-color: $color-blue-dark;

    &:hover {
      color: $color-blue-dark;
      background-color: $color-yellow;
    }
  }

  &-transparente {
    color: $color-blue-medium;
    background-color: transparent;
    padding: 0.5rem;
    transition: color 0.4s ease-in-out;

    &:hover {
      color: $color-white;
    }

  }

  &-transparente-whit-icons {
    position: relative;
    display: block;
    color: $color-blue-medium;
    background-color: $color-yellow;
    border: 2px solid $color-yellow;
    text-align: center;
    padding: 0.5rem 4rem 0.5rem 3rem;
    transition: color 0.4s ease-in-out;

    &::after {
      content: "\e903";
      font-family: "icomoon";
      color: inherit;
      font-size: 2.8rem;
      position: absolute;
      top: 50%;
      right: 2rem;
      transform: translateY(-50%) rotate(-90deg);
    }

    &:hover {
      color: $color-yellow;
      background-color: transparent;
    }
  }

  &-subscribe {
    color: $color-blue;
    background-color: $color-yellow;
    font-size: 3.5rem;
    font-weight: 900;
    height: auto;
    padding: 0.8rem 1.5rem;
    border-radius: 0;
    transform: scale(0.95);
    transition: all 0.4s ease-in-out;


    &:hover {
      transform: scale(1.0);
    }
  }


  &-green {
    color: $color-white;
    // background: linear-gradient(90deg, $color-dark-green, $color-blue);
    background-color: $color-green;
    font-size: 32px;
    height: 4rem !important;
    transition: all .5s linear;

    &:hover {
      background: inherit;
      color: $color-blue;
      transition: background .5s linear;
      background-color: $color-yellow;
    }


    &-solid {
      background: none;
      background-color: $color-blue;

      &:hover {
        background-color: transparent;
        border: 2px solid $color-blue;
        border-radius: 1.2rem;
      }
    }



    &-outline {
      background: none;
      background-color: transparent;
      border: 2px solid $color-green;
      border-radius: 1.2rem;

      &:hover {
        background-color: $color-green;
        border: 2px solid $color-blue;
        color: $color-white;
        border-radius: 1.2rem;
      }
    }
  }

  &-blue {
    color: $color-white;
    background-color: $color-blue;
    font-size: 32px;
    height: 5.5rem;
    padding: 11px 0;

    &:hover {
      color: $color-white;
      background-color: $color-green;
      transition: all .5s ease-out;
      padding: 11px 0;
    }
  }

  &-yellow {
    font-size: 16px;
    padding: 11px 73px;
    background-color: $color-yellow;
    color: $color-blue;
    height: 39px;
    margin: 0 auto;

    &:hover {
      background-color: $color-orange;
    }
  }
}