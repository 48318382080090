.whatsapp {
  width: 5rem;
  height: 5rem;
  text-align: center;
  position: fixed;
  right: 1%;
  bottom: 20%;
  padding: 0.8rem;
  background: #03C100;
  color: $color-white;
  font-size: 2.8rem;
  border-radius: 10%;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
  z-index: 999999999;
  
  @include media-query(tablet) {
    bottom: 8%;
  }

  &:hover {
    background: $color-yellow;
  }

  .icon-whatsapp {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}