.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;

  z-index: 990;
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  pointer-events: none;

  &.show {
    opacity: 1;
    pointer-events: auto;
    overflow: auto;
  }

  .menu-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5vh;
  }

  .box-menu {
    position: relative;
    padding: 0rem 0rem 5rem;
    -webkit-transition: all 500ms ease-in;
    -moz-transition: all 500ms ease-in;
    transition: all 500ms ease-in;

    .menu-header {
      p {
        color: $color-yellow;
        font-size: 24px;

        @media (max-width:$mobile) {
          text-overflow: ellipsis;
          max-width: 90%;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

  }

  .close {
    width: 2.8rem;
    height: 2.8rem;
    background-color: transparent;
    background-image: url(./assets/images/close-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: $color-white;
    line-height: 2.5rem;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 3rem;
    border: none;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    @include media-query(mobile) {
      top: 2rem;
    }

    &:hover {
      color: $color-yellow;
    }
  }

  .logo-posforum {
    margin-top: 5vh;
    margin-bottom: 10vh;
    text-align: center;

    img {
      width: 20rem;

      @include media-query(mobile) {
        width: 25rem;
      }

      @include media-query(tablet) {
        width: 33rem;
      }

      @include media-query(desktop) {
        width: 35rem;
      }
    }
  }

  .navbar-nav {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 3vh;
    font-weight: 800;
    line-height: 1em;

    @include media-query(mobile) {
      font-size: 4vh;
    }

    @include media-query(desktop) {
      font-size: 5vh;
    }

    @include media-query(desktopL) {
      font-size: 7vh;
    }
  }

  .nav {
    &-item {


      a[data-link="cerrar-sesion"] {
        border: transparent;
        color: $color-yellow  ;
        font-weight: 500;
        text-decoration-line: underline;
        text-decoration-color: $color-yellow  ;

        &:hover {
          background-color: transparent;
        }


      }
    }

    &-link {
      color: $color-white;
      text-decoration: none;
      border: 2px solid $color-green;
      border-radius: 1.2rem;
      padding: 2rem 1.8rem;
      position: relative;
      transition: all 0.7s;
      -webkit-transition: all 0.7s;
      -moz-transition: all 0.7s;
      width: 100%;
      display: block;
      text-align: center;
      margin: 4rem 0;

      @media (max-width:$desktop) {
        font-size: 1.8rem;
      }

      &:hover {
        // padding-left: 5.5vw;
        background-color: $color-blue;
        color: $color-white;
        border: 2px solid $color-blue;

        // & .nav-link::before {
        //   opacity: 1;
        //   transition: all 0.5s;
        //   transition-delay: 0.4s;
        // }
      }

      // &::before {
      //   content: '';
      //   margin-right: 1vw;
      //   position: absolute;
      //   top: 50%;
      //   left: 0%;
      //   transform: translate(0%, -50%);
      //   border: 4px solid $color-white;
      //   background-color: $color-white;
      //   width: 4.5vw;
      //   opacity: 0;
      // }

    }
  }
}