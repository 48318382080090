.section__home {
  .pages-internal {
    .container {
      position: relative;
      height: 100%;
      padding-top: 0;

      @include media-query(desktop) {
        padding-top: 14.5vh;
      }

      @include media-query(desktopXL) {
        padding-top: 15.5vh;
      }
    }

    &-content {
      width: 100%;

      .se-pro {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        padding-top: $header-height;

        // @include media-query(mobilex) {
        //   padding-top: 12rem;
        // }

        @include media-query(desktop) {
          flex-direction: row;
          padding-top: 4rem;
        }

        @include media-query(desktopL) {
          padding-bottom: 0;
        }

        .scroll-home {
          display: flex;
          flex-direction: column;
          height: auto;
          align-items: center;
          gap: 0.2rem;
          @extend %home-position-absolute;
          left: 0;

          img {
            width: 5rem;
          }

          .text-paragraph {
            color: $color-yellow;
            font-weight: $semibold;
          }
        }

        .col-left {
          height: 100%;
          position: relative;
          text-align: center;

          @media(min-width: $desktopL) {
            height: 100vh;
            position: relative;
            text-align: center;
            display: flex;
            // align-items: end;
            align-items: center;
            justify-content: center;
          }

          .logo-sepro-mobile-home {
            // margin-top: -19%;

            img {
              width: 50%;
              max-width: 25rem;
            }

            @media(min-width:$desktop) {
              display: none;
            }


          }

          img {
            // display: block;
            height: auto;
            max-width: max-content;
            width: 100%;
            margin-bottom: -5px;
            position: relative;
            // left: -3%;

            @media(min-width: $desktopL) {
              height: auto;
              width: 100%;
              margin-bottom: -5px;
              position: relative;
              left: -3%;
            }

            @media (max-width:$desktop) {
              max-width: 100%;
            }


          }



          // a.link-channel {
          //   position: absolute;
          //   bottom: -0.4%;
          //   right: 4.8%;
          //   width: 21.5%;
          //   height: 4.3%;
          // }
        }

        .col-right {
          border-radius: 16px;


          .main-information {
            padding: 1.5rem;
            border-radius: 10px;
            max-width: 830px;

            .sepro-branding {
              text-align: center;

              img {
                // height: 90vh;
                position: relative;

                @media (max-width:$desktop) {
                  right: 0;
                }
              }
            }

            h1 {
              font-size: 4.6rem;
              color: $color-blue;
              font-weight: 700;

              @media (max-width:$desktop) {
                font-size: 4.2rem;
                text-align: center;
              }

              span {
                display: block;
                padding-left: 2.5rem;

                @media (max-width:$desktopXL) {
                  font-size: 3.5rem;
                }

                @media (max-width:$desktop) {
                  font-size: 3rem;
                  font-weight: 300;
                  padding-left: 1.5rem;
                }
              }
            }

            p {
              font-size: 3.2rem;

              @media (max-width:$desktopXL) {
                font-size: 3rem;
              }

              @media (max-width:$desktop) {
                font-weight: 700;
              }

              &.description {
                @media (max-width:$desktop) {
                  font-size: 2.4rem;
                  font-weight: 400;
                }
              }
            }

            .btn-green {
              width: 100%;
              justify-content: center;
              align-items: center;
              font-size: 3.2rem;
              display: none;

              @include media-query(desktop) {
                display: flex;
              }
            }
          }

        }

        .icon-slogan {
          @extend %home-position-absolute;
          right: 0;

          img {
            width: 20rem;
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        font-size: 1.2rem;

        .footer-text {
          &:last-child {
            font-size: 1.3rem;
            font-weight: 600;
            line-height: 1.8;

            .link-tyc {
              color: $color-yellow;
            }
          }
        }

      }
    }


  }



}

.btn-create-user-mobile {
  display: none;

  @media(max-width:$desktop) {
    display: block;

    .btn-blue {
      font-size: 24px;
      height: 5.5rem;
      text-align: center;
      padding: 11px 0;
      display: flex;
      justify-content: center;
      width: 71%;
      margin: 2% auto;

      @media (max-width:$mobile) {
        font-size: 16px;

      }
    }


  }
}