.referred-landing {

  .right {
    position: absolute;
    left: calc((100% / 12)* 7);
    height: 100%;
    z-index: 3;

    @media (max-width: 768px) {
      display: none;

      &.show {
        display: block;
        top: 0;
        left: 0;
        width: 100vw;
      }
    }
    .sticky {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: sticky;
      top: 0px;

      .form-title {
        position: relative;
        top: 2.2vw;
        width: 20vw;

        &.posgrado {
          visibility: hidden;
        }

        @media (max-width: 768px) {
          display: none;
        }
      }

      .referred-form-btn-close {
        display: none;
        width: 3.5vw;
        aspect-ratio: 1 / 1;
        border: none;
        background: transparent;
        position: fixed;
        top: 3.5vh;
        right: 10vw;
        z-index: 1;

        @media (max-width: 768px) {
          display: block;
        }

        svg {
          width: 100%;
        }
      }

      #referred-form {
        width: 100%;
        max-height: 712px;
        max-width: 500px;
        aspect-ratio: 0.7;
        border-radius: 23px;
        border: 2px solid var($color-white);
        box-shadow: 3px 2px 4px 0px rgba(0, 0, 0, 0.10);
        
        &.show {
          @media (max-width: 768px) {
            position: fixed;
            top: 1vh;
            left: 3vw;
            height: 90vh;
            width: 94vw;
            max-width: 100%;
            max-height: 100vh;
          }
        }

        >body {
          background: transparent;
        }
      }

      .sticky-footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 1rem;

        @media (max-width: 768px) {
          display: none;
        }

        .sticky-footer-btn {
          display: inline-flex;
          padding: 0.5dvw 1.5dvw;
          justify-content: center;
          align-items: center;
          gap: 10px;
          font-size: 1dvw;          
          border-radius: 2rem;
          text-decoration: none;

          &.pregrado {
            color: $color-white;
            background-color: $color-turquoise-referred-pre;
            &:hover {
              color: $color-turquoise-referred-pre;
              background-color: $color-yellow-referred-pre;
            }
          }

          &.posgrado {
            color: $color-blue-text-referred-pos;
            background-color: $color-yellow-btn-referred-pre;
            &:hover {
              color: $color-white;
              background-color: $color-skyblue-text-referred-pos;
            }
          }
        }

        .sticky-footer-uparrow {
          display: flex;
          justify-self: flex-end;
          height: 2dvw;
          aspect-ratio: 1 / 1;
        }
      }
    }
  }

  .referred-content {
    display: flex;
    flex-direction: column;
    // font-family: Goldplay;
    
    @media (max-width: 768px) {
      align-items: center;
    }

    &.pregrado {
      background-color: $color-orange-bg-referred-pre;
    }

    &.posgrado {
      background-color: $color-darkblue-bg-referred-pos;
    }

    .referred-footer {
      background-color: $color-white;
      min-height: 280px;
      width: 100%;
      padding: 4rem 0;
      align-content: center;
      color: $color-gray-text-referred-pre;
  
      @media (max-width: 768px) {
        text-align: center;
        height: 82vw;
        align-content: flex-start;
      }
  
      .referrred-footer-btn {
        display: none;
        z-index: 2;
        font-size: 3.7vw;
        text-decoration: none;
        position: absolute;
        bottom: 77.5vw;
        left: 12.5%;
        width: 75%;
        height: 9vw;
        place-items: center;
        border-radius: 2rem;
        @media (max-width: 768px) {
          display: grid;
        }

        &.pregrado {
          background-color: $color-yellow-btn-referred-pre;
          color: $color-blue-text-referred-pre;
          &:hover {
            color: $color-yellow-btn-referred-pre;
            background-color: $color-blue-text-referred-pre;
          }
        }
        &.posgrado {
          background-color: $color-yellow-btn-referred-pre;
          color: $color-darkblue-bg-referred-pos;
          &:hover {
            color: $color-white;
            background-color: $color-skyblue-text-referred-pos;
          }
        }
      }
  
      .footer-grid-row {
        width: 100%;
        display: flex;
        flex-direction: row;
        font-weight: 400;
        color: $color-darkgray-text-referred-pre;
  
        @media (max-width: 768px) {
          flex-direction: column;
          place-items: center;
          font-size: 3.4vw;
          width: 100%;
        }
  
        picture, p {
          width: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          line-height: 1.2;
          font-size: 1.2vw;
  
          @media (max-width: 768px) {
            width: inherit;
            .logo-sombrilla-footer {
              width: 75%;
            }
          }
  
        }
      }
  
      .footer-grid-row-bottom-text {
        line-height: 1.2;
        font-size: 1vw;
        padding-top: 16px;
  
        @media (max-width: 768px) {
          line-height: 1.4;
          font-size: 2.8vw;
          padding: 4% 3% 0%;
        }
      }
    }
  
    .backgrounds {
      width: 100%;
      position: absolute;
      top: 0;
  
      .backgrounds-banner {
        display: flex;
        flex-direction: column;
        width: 100%;
        background: none;
  
        .backgrounds-banner-background {
          width: 100%;
          position: relative;
        }
      }
  
      .backgrounds-wave-image {
        position: absolute;
        top: calc(100vw * 0.46);
        width: 100%;
        z-index: 1;
    
        @media (max-width: 768px) {
          top: calc(100vw * 0.96);
        }
      }
    }

    .referred-banner-leftside {
      width: 100%;
      margin-top: 90px;

      @media (max-width: 768px) {
        margin-top: 30px;
      }

      .referred-banner-leftside-container {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .referred-section-banner-logo {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          height: calc(4.79vw);
          width: calc(19.86vw);
          padding-left: 6%;

          @media (max-width: 768px) {
            justify-content: center;
            padding-left: 0;
            width: 100%;
            height: auto;
          }

          .referred-section-banner-logo-combine {
            @media (max-width: 768px) {
              width: 60%;
            }
          }
        }
      }

      .referred-banner-leftside-footer{
        display: flex;
        justify-content: flex-end;
        padding-right: 14%;

        @media (max-width: 768px) {
          padding-right: 0;
          font-size: calc(100vw * 0.029);
          justify-content: center;
        }
      }
    }

    .mini-form {
      display: none;
      width: 110%;
      padding: 4%;
      margin: 4% 0 15%;
      flex-direction: column;
      align-items: center;
      font-style: normal;
      line-height: normal;
      border-radius: 16px;
      background: rgba(255, 255, 255, 0.80);
      box-shadow: 3px 2px 4px 0px rgba(0, 0, 0, 0.10); 

      @media (max-width: 768px) {
        display: flex;
      }

      .mini-form-text {
        color: #005568;
        text-align: center;
        // font-family: Goldplay;
        padding-bottom: 3%;
        .mini-form-text-title {
          font-size: calc(100vw*0.055);
          font-weight: 700;
          line-height: 1.7; /* 28.8px */ 
        }

        .mini-form-text-content {
          text-align: center;
          font-size: 3.2vw;
          font-weight: 400;
          line-height: 1.4;
        }
      }

      .mini-form-btn {
        display: flex;
        width: 100%;
        height: 8vw;
        padding: 3%;
        align-items: flex-start;
        background-color: $color-white;
        border-radius: 1rem;
        border-style: none;

        &:hover {
          background-color: $color-softgray-bg-referred-pre;
        }

        .mini-form-btn-text {
          color: $color-softgray-bg-referred-pre;
          // font-family: Rubik;
          font-size: 12px;
          font-weight: 400;

          &:hover {
            color: $color-white;
          }
        }
      }
    }
  }

  .sticky-mobile-btn {
    display: none;
    position: fixed;
    z-index: 3;
    bottom: 3vh;
    left: 20vw;
    width: 60vw;
    height: 11vw;
    border-radius: 2rem;
    border: none;
    place-items: center;
    font-size: 4vw;
    color: $color-blue-text-referred-pre;
    background-color: $color-yellow-referred-pre;
    box-shadow: 0px -5px 5px 0px rgba(0, 53, 105, 0.10);

    svg {
      height: 3vw;
    }

    @media (max-width: 768px) {
      display: grid;
    }
  }

  .left {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8.33%;
    z-index: 2;

    @media (max-width: 768px) {
      width: 100%;
    }
    .referred-info {
      padding-top: 2vw;
      @media (max-width: 768px) {
        width: 100%;
      }

      article {
        padding-bottom: 2%;
        overflow: hidden;
      }

      .article-header {
        .referred-section-content-miniwave-1 {
          @media (max-width: 425px) {
            width: 25vw;
          }
        }
      }

      .article-title {
        @media (max-width: 768px) {
          font-size: calc(100vw*0.033);
        }
      }

      .article-content {
        line-height: 1.4;
        font-size: large;
        font-weight: 300;
        @media (max-width: 768px) {
          font-size: calc(100vw*0.041);
        }

        strong {
          font-weight: bold;
        }

        .article-row {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 10px;
          @media (max-width: 768px) {
            flex-direction: column-reverse;

            picture {
              display: flex;
              flex-direction: column-reverse;
            }
          }

          .referred-section-content-miniwave-2 {
            position: relative;
            top: -10px;
            @media (max-width: 768px) {
              justify-items: flex-start;
              position: relative;
              top: 2rem;
              left: 0;
              width: 25vw;
            }
          }

          .referred-section-content-image {
            padding-top: 20px;

            @media (max-width: 768px) {
              width: 100vw;
              padding-left: 0vw;
              padding-right: 5vw;
              padding-top: 10vw;
            }
          }

          .article-grid-row {
            display: grid;
            place-items: center;
            grid-template-columns: 1fr auto;
            column-gap: 10px;
            padding-top: 15px;

            @media (max-width: 768px) {
              column-gap: 5vw;
            }

            picture {
              width: 60px;
              height: 60px;
              border-radius: 50%;
              // display: flex;
              // align-items: center;
              // justify-content: center;
              display: grid;
              place-items: center;

              &.pregrado {
                background: $color-yellow-referred-pre;
              }
              &.posgrado {
                background: $color-skyblue-text-referred-pos;
              }

              @media (max-width: 768px) {
                width: 11vw;
                height: 11vw;
                .referred-section-content-number {
                  height: 8vw;
                }
              }
            }

            strong {
              &.pregrado {
                color: $color-yellow-btn-referred-pre;
              }
            }
          }
        }
      }

      .article-footer {
        display: flex;
        justify-content: flex-end;
        padding: 8% 0;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .article-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 32px;
        padding: 2% 0;

        @media (max-width: 768px) {
          display: flex;
          flex-direction: column;
        }

        .article-grid-card {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 10%;
          box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.10);
          border-radius: 13px;
          &.pregrado {
            background-color: $color-turquoise-referred-pre;
          }
          &.posgrado {
            background-color: $color-yellow-btn-referred-pre;
          }

          @media (max-width: 768px) {
            font-size: calc(100vw*0.039);
            width: 82%;
            margin-left: 9%;
          }
        }

        .owl-dots {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: relative;
          top: 100px;
          left: -240vw;

          @media (max-width: 768px) {
            justify-content: flex-start;
            align-items: flex-start;
            top: 90%;
            left: 40%;
            width: 20%;
          }
        
          .owl-dot {
            background-color: rgba(255, 255, 255, 0.1);
            width: 2.6vw;
            height: 2.6vw;
            margin-right: 0.6rem;
            border-radius: 1.2rem;
            transition: all .5s linear;
            border: 0;
            cursor: pointer;

            &.active {
              transition: all .5s linear;
              &.pregrado {
                background-color: $color-yellow-owl-referred-pre;
              }
              &.posgrado{
                background-color: $color-skyblue-owl-referred-pos;
              }
            }
          }
        }
      }

      .owl-carosuel {
        padding-bottom: 17%;
      }
    }
  }

  .right.pregrado::before,
  .referred-content.pregrado::before {
    content: "";
    position: absolute;
    top: calc(100vw * 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    background: url(./assets/images/content/referido-pregrado/contenido-referido-pregrado-desktop.png);
    @media (max-width: 768px) {
      background-image: url(./assets/images/content/referido-pregrado/contenido-referido-pregrado-mobile.png);
      top: calc(100vw * 0.96);
    }
  }

  .right.posgrado::before,
  .referred-content.posgrado::before {
    content: "";
    position: absolute;
    top: calc(100vw * 0.5);
    left: 0;
    right: 0;
    bottom: 0;
    background: url(./assets/images/content/referido-posgrado/contenido-referido-posgrado-desktop.png);
    @media (max-width: 768px) {
      background-image: url(./assets/images/content/referido-posgrado/contenido-referido-posgrado-mobile.png);
      top: calc(100vw * 0.96);
    }
  }

  .footer-register {
    display: none;
  }
}