.benefit-card {
  background-color: $color-white;
  border-radius: 12px;
  padding: 4.2rem 4.2rem 5.6rem 3.4rem;
  min-width: 100%;
  height: auto;
  min-height: 300px;
  max-width: 300px;

  // @media (max-width: $desktopXL) {
  //   min-width: 250px;
  //   max-width: 250px;
  // }
  @media (max-width: $desktopL) {
    padding: 4.2rem 2.6rem 2rem;
  }

  // @media (max-width:$tablet) {
  //   max-width: 90%;
  //   margin-right: 2rem;
  // }
  &-header {
    // margin-top: -4rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .benefit-icon-container {
      width: 7.5rem;
      height: 5.5rem;
      padding: 2.5rem;
      border-radius: 1.2rem;
      box-shadow: -3px 5px 11px 0px #b5b4b48f;

      .benefit-icon {
        width: 100%;
        height: auto;
      }
    }

    .benefit-title {
      font-size: 2.7rem;
      padding-left: 1rem;

      span {
        display: block;
      }
    }

  }

  &-body {
    margin-top: 3.5rem;
    text-align: left;
    padding-left: 3rem;

    .description {
      @include media-query(desktopXL) {
        font-size: 20px;
      }
    }
  }
}

.benefits-section {
  position: relative;
  z-index: 1;

  @media (max-width: $desktop ) {
    height: 70vh;
    overflow: hidden;
    margin: 0;
  }

  @media (max-width: $tablet ) {
    height: 80vh;
    overflow: hidden;
    margin: 0;
  }

  @media (max-width: $mobile ) {
    height: 160vh;
    overflow: hidden;
    margin: 0;
  }

  @media (max-width: $mobilexl ) {
    height: auto;
    overflow: initial;
  }

  .benefits-section-banner {
    width: 100%;
    height: auto;

    @media (min-width:$mobilex ) {
      height: 130vh;
    }

    @media (min-width:$mobilexl ) {
      height: 124vh;
    }

    @media (min-width:$mobile ) {
      height: 160vh;
    }

    @media (min-width:$tablet ) {
      object-fit: cover;
    }

    @media (max-width: $desktop ) {
      object-fit: cover;
    }



  }

  .title {
    font-size: 4rem;
    margin-bottom: 8rem;
    text-align: center;
    color: $color-white;

    @media (max-width:$desktopXL) {
      font-size: 4rem;
      margin-bottom: 8rem;
    }

    @media (max-width:$desktop) {
      font-size: 3rem;
      margin-bottom: 2rem;

    }

    @media (max-width:$mobile) {
      font-size: 2.4rem;
      margin-bottom: 2rem;

    }

    @media (max-width:$tablet) {

      strong {
        color: $color-yellow;
      }
    }

    span {
      display: block;
      width: 25%;
      text-align: center;
      margin: 0 auto;

      @media (max-width:$desktop) {
        width: 65%;
      }

      &.bg-green-text {
        border-radius: 0;
      }
    }
  }

  .benefits-section-info {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50%;
    transform: translateY(-40%);
    z-index: 2;

    @media(max-width:$mobile) {
      transform: translateY(-47%);
    }

    // display: flex;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
    .benefits {
      display: flex;
      flex-direction: row;
      gap: 12px;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: hidden;
      position: relative;
      padding-bottom: 10rem;

      @media (max-width:$desktop) {
        justify-content: flex-start;
      }

      .owl-dots {
        position: absolute;
        width: 100%;
        height: auto;
        left: 0;
        bottom: 10%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .owl-dot {
          background-color: #D9D9D9;
          width: 75px;
          height: 11px;
          margin-right: 1rem;
          border-radius: 0.2rem;
          transition: all .5s linear;
          border: 0;

          &.active {
            transition: all .5s linear;
            background-color: $color-green;
          }
        }
      }


      &-info {
        .benefits-summary {
          max-width: 800px;
          margin: 0 auto;
          padding-bottom: 6rem;

          @media(max-width:$mobile) {
            padding-bottom: 0;
          }

          h4 {
            color: $color-white;
            text-align: center;
            font-size: 2.4rem;
            font-weight: 400;

            @media (max-width:$desktop) {
              font-size: 2rem;
              padding-bottom: 2rem;
              text-align: left;
            }


            @media (max-width:$mobile) {
              font-size: 1.6rem;
              padding-bottom: 2rem;
            }
          }
        }

        .btn {
          margin: 0 auto;
          justify-content: center;
          transition: all .5s linear;
          max-width: 750px;
          font-size: 26px;
          background-color: $color-green;
          color: $color-white;
          text-align: center;
          display: none;

          @media(min-width:$desktop) {
            display: flex;
          }

          @media (max-width:$desktop) {
            max-width: 100%;
            font-size: 2.2rem;
            text-align: center;
          }

          @media (max-width:$mobile) {
            font-size: 1.6rem;
          }
        }


      }

      .benefit-card-header {
        .benefit-icon-container {
          padding: 0;
        }
      }
    }


  }

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    z-index: 1;
    height: 100%;
    background: $color-dark-blue;
    opacity: .8;
    z-index: 1;
    left: 0;
    top: 0;
    opacity: .8;
  }
}


.benefits-landing-section {
  padding: 7rem 0 6rem;

  h1 {
    span {
      display: block;
      font-weight: 400;
    }

    @media (max-width:$desktop) {
      text-align: center;
      font-size: 3.2rem;
    }
  }

  .benefits {
    overflow: hidden;
    position: relative;
    width: 100%;

    @media (max-width:$desktopL) {
      padding: 0rem 0 6rem 0;
    }

    .benefit-card {
      max-height: 225px;
      padding: 2rem 1.5rem;
      min-height: 225px;

      &-header {
        justify-content: center;

        .benefit-icon-container {
          width: auto;
          height: auto;
          background-color: transparent;
          border: none;
          box-shadow: none;
          padding: 0;
        }
      }

      &-body {
        margin-top: 1.5rem;
        text-align: center;
        padding-left: 0;

        .description {
          font-size: 1.8rem;
          font-weight: 600;
        }
      }
    }

    .owl-dots {
      bottom: 0%;

      .owl-dot {
        // background-color: rgba($color: $color-white , $alpha: 0.5);
        background-color: $color-white;
        opacity: 0.5;

        &.active {
          background-color: $color-green;
          opacity: 1;
        }
      }
    }
  }
}

.features-section {
  @media (max-width:$desktop) {
    padding-top: 0;
  }

  .row {
    @media (max-width:$desktop) {
      flex-direction: column-reverse;
    }
  }

  .left-col {
    padding: 6rem 1rem 8rem 15%;
    background-color: #F9F9F9;

    @media (max-width:$desktopXL) {
      padding: 6rem 1rem 8rem 10%;
    }

    @media (max-width:$desktopL) {
      padding: 6rem 1rem 8rem 5%;
    }

    @media (max-width:$desktop) {
      padding: 6rem 1rem 4rem 1rem;
    }

    h2 {
      font-weight: 400;
      font-size: 3.6rem;
      width: 80%;
      margin-bottom: 2rem;

      @media (max-width:$desktop) {
        text-align: center;
        font-size: 3.2rem;
        display: none;
      }
    }

    .features-section-list {
      list-style: none;

      .feature-item {
        margin-bottom: 1.5rem;
        position: relative;
        padding-left: 2rem;
        font-size: 2rem;

        &::after {
          content: '';
          position: absolute;
          background-color: $color-soft-black;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          left: .5rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .right-col {
    position: relative;

    img {
      width: 100%;
      height: 100%;
      background-size: cover;
      object-fit: cover;
    }

    h3 {
      position: absolute;
      bottom: 10%;
      left: 8%;
      z-index: 10;
      width: 82%;
      display: none;
      font-size: 3.6rem;

      @media (max-width:$desktop) {
        display: block;
      }
    }

    @media (max-width:$desktop) {
      &::after {
        content: "";
        position: absolute;
        background: linear-gradient(90deg, $color-dark-blue, $color-blue );
        z-index: 9;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: .7;
      }
    }

  }
}