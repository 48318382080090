.conferences {
  margin-bottom: 8rem;

  .section-body {
    position: relative;
    height: 100%;
    width: 100%;
    padding-top: 25.5vh;
    margin: auto;
    // display: flex;
    // justify-content: center;

    @include media-query(tablet) {
      width: 95%;
    }

    @include media-query(desktopL) {
      width: 80%;
    }

    @include media-query(desktopLS) {
      width: 65%;
    }
  }

  .schedule {
    &__item {
      &__title {
        font-size: 4rem;
        text-align: center;
        margin-bottom: 1.5rem;

        @include media-query(desktop) {
          font-size: 5rem;
        }

        span {
          color: $color-yellow;
        }
      }

      &__data {
        border-top: 1px solid $color-white;
        padding: 2rem 3rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 1rem;

        @include media-query(tablet) {
          padding: 2rem 5rem;
        }
        @include media-query(desktop) {
          padding: 2rem 8rem;
          flex-direction: row;
          gap: 5rem;
        }

        .data__left {
          .title {
            font-size: 4rem;

            @include media-query(desktop) {
              font-size: 5rem;
            }
          }

          .subtitle {
            font-size: 2rem;
            padding-left: 0.5rem;
          }
        }

        .data__right {
          display: flex;
          flex-direction: row;

          @include media-query(desktop) {
            width: 50%;
          }

          .link {
            display: block;
            width: 100%;
            opacity: 1;
            margin-right: 3rem;
            align-self: center;
            max-width: 6rem;
            transition: all 0.4s ease-in-out;
            
            @include media-query(desktop) {
              position: relative;
              left: -6rem;
              opacity: 0;
              margin-right: 0;
            }
          }

          .link-video {
            display: block;
            width: 6rem;
            color: $color-white;
            font-size: 1.2rem;
            text-decoration: none;

            img {
              max-width: 5rem;
              display: block;
              margin: auto;
            }

            span {
              display: block;
              text-align: center;
            }
          }

          .info {
            border: 0;
            min-height: 10rem;
            padding: 1rem 0 0 3rem;
            position: relative;

            @include media-query(desktop) {
              border-left: 1px solid $color-yellow;
              min-height: 14rem;
              position: relative;
              left: -6rem;
              transition: all 0.4s ease-in-out;
            }

            .title {
              color: $color-yellow;
            }

            &::before,
            &::after {
              content: "";
              display: none;
              width: 0.6rem;
              height: 0.6rem;
              border-radius: 50%;
              background: $color-yellow;
              position: absolute;
              left: -0.37rem;
              
              @include media-query(desktop) {
                display: block;
              }
            }

            &::before {
              top: 0;
            }

            &::after {
              bottom: 0;
            }
          }

          &:hover {
            .link {
              @include media-query(desktop) {
                left: 0;
                opacity: 1;
                margin-right: 4rem;
              }
            }

            .info {
              @include media-query(desktop) {
                left: 0;
              }
            }
          }
        }
      }
    }

    .slider {

      .owl-nav {

        .owl-prev,
        .owl-next {
          width: 2.1em;
          height: 2.1em;
          background-size: cover;
          background: transparent;
          color: transparent;
          text-decoration: none;
          position: absolute;
          top: 2.6rem;
          
          @include media-query(desktop) {
            width: 2.5em;
            height: 2.5em;
            top: 3.6rem;
          }

          &:hover {
            color: transparent;
          }

          span {
            display: none;
          }
        }

        .owl-prev {
          background-image: url('./assets/images/arrow-left-active-slider.svg');
          left: 0;
          
          @include media-query(mobile) {
            left: 20%;
          }
          @include media-query(desktop) {
            left: 24rem;
          }
        }

        .owl-next {
          background-image: url('./assets/images/arrow-rigth-active-slider.svg');
          right: 0;
          
          @include media-query(mobile) {
            right: 20%;
          }
          @include media-query(desktop) {
            right: 24rem;
          }
        }
      }

    }
  }
}