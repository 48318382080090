.sepro-banner{
  padding-top: 8rem;
  margin-bottom: -1rem;
  @media (max-width:$desktop) {
    img{
      margin-bottom: -1.2rem;
    }
  }
  img{
    width: 100%;
    height: auto;
  }
}