.geolocation-section {
  padding: 5rem 0;
  background-color: $color-white;

  @media (max-width:$desktop) {
    padding: 2.5rem 0;
  }

  .main-info {
    @media (max-width:$desktop) {
      padding: 0 1rem;
    }

    h2 {
      font-size: 7rem;
      color: $color-blue;
      margin-bottom: 2rem;
      text-transform: uppercase;

      @media (max-width:$desktop) {
        font-size: 3.8rem;

        span {
          font-size: 4.8rem;
        }
      }

      span {
        color: $color-blue;
        // background-color: $color-blue;
        display: block;
        width: fit-content;
        padding: 0 2rem;
      }
    }

    .description {
      font-size: 4rem;

      @media (max-width:$desktop) {
        font-size: 2.4rem;

      }
    }
  }

  .sites-info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width:$desktop) {
      overflow: hidden;
      display: none;
    }

    .owl-item {
      padding: 1.5rem;
    }

    .site {
      width: 50%;
      padding: 0 1rem;
      margin: 1rem 0;

      @media (max-width:$desktop) {
        width: 100%;
        background-color: #F9F9F9;
        box-shadow: -3px 4px 6px 1px #bfbebe9c;
        border-radius: 10px;
        padding: 2rem 1rem;
      }

      .site-title {
        background-color: $color-soft-black;
        padding: .2rem .8rem;
        border-radius: 5px;
        color: $color-white;
        font-weight: 500;
        width: fit-content;
        font-size: 1.6rem;
        margin: 1.2rem 0;
      }

      .site-list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        gap: 5px;

        @media (max-width:$desktop) {
          flex-wrap: nowrap;
          flex-direction: column;

        }

        .site-item {
          color: black;
          font-size: 1.6rem;

          @media (max-width:$desktop) {
            position: relative;
            padding-left: 2rem;

            &::after {
              position: absolute;
              content: "";
              background-color: $color-soft-black;
              width: 8px;
              height: 8px;
              left: 5px;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 50%;
            }
          }
        }
      }

      &.caribe {
        .site-title {
          background-color: #009FE3;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #009FE3;
            }
          }
        }
      }

      &.sur {
        .site-title {
          background-color: #545FA8;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #545FA8;
            }
          }
        }
      }

      &.antioquia-choco {
        .site-title {

          background-color: #312783;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #312783;
            }
          }
        }
      }

      &.cundinamarca {
        .site-title {

          background-color: #F39200;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #F39200;
            }
          }
        }
      }

      &.eje-cafetero {
        .site-title {

          background-color: #D60B52;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #D60B52;
            }
          }
        }
      }

      &.sur-occidente {
        .site-title {

          background-color: #00A47D;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #00A47D;
            }
          }
        }
      }

      &.orinoquia {
        .site-title {

          background-color: #BE4090;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #BE4090;
            }
          }
        }
      }

      &.magdalena-medio {
        .site-title {

          background-color: #A3195B;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #A3195B;
            }
          }
        }
      }

      &.santanderes {
        .site-title {

          background-color: #009640;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #009640;
            }
          }
        }
      }

      &.bogota {
        .site-title {

          background-color: #E6332A;
        }

        .site-item {
          @media (max-width:$desktop) {
            &::after {
              background-color: #E6332A;
            }
          }
        }
      }

    }

    .owl-dots {
      bottom: 0;

      .owl-dot {
        width: 60px;

      }
    }
  }

  .sites-info-mobile {
    display: none;
    overflow: hidden;
    padding: 5rem 0;
    position: relative;

    @media (max-width:$desktop) {
      display: block;

      .field-rectory {
        text-align: center;
      }
    }

    .owl-dots {
      bottom: 0;
    }
  }

  .geolocation-info {
    position: relative;

    .main-info {
      .bg-gradient-blue {
        background: $color-yellow;
        border-radius: 0.2em;
      }
    }
  }

  .geolocation-map {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .geolocation-map {
    @media (max-width:$desktop) {
      display: none;
    }
  }
}