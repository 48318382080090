@import './base/normalize';

@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;900&display=swap');

@import './base/variables';
@import './base/mixins';
@import './base/base';
@import './base/icon-fonts';
@import './base/texts';
@import './base/extends';
@import './components/animations';
@import './base/buttons';
@import './base/grid';
@import './components/header';
@import './components/menu';
@import './components/home';
@import './components/forms';
@import './components/modal';
@import './components/pagesInternal';
@import './components/about';
@import './components/postgraduate';
@import './components/conferences';
@import './components/tooltip';
@import './components/owl.carousel.css';
@import './components/owl.theme.default.css';
@import './components/slider';
@import './components/footer';
@import './components/whatsapp';
@import './components/recovery';
@import './components/benefits';
@import './components/banner';
@import './components/map';
@import './components/services';
@import './components/events';
@import './components/find-program';
@import './components/404';
@import './components/referred-pre';