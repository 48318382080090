.rebote {
  animation: rebote 1.5s infinite;
}

@keyframes rebote {
  0% {
    transform: translateY(-1rem);
    opacity: 1;
  }
  50% {
    transform: translateY(-0.3rem);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0);
    opacity: 0;
  }
}